



















import {Component, Vue, Watch} from 'vue-property-decorator';
import EditContactForm from "@/components/contacts/EditContactForm.vue";
import {Action, Getter} from "vuex-class";
import IContact from "@/interfaces/models/contact.interface";
import * as actionTypes from "@/store/action-types";

@Component({
  components: {EditContactForm}
})
export default class EditContactDrawer extends Vue {
  @Getter('isDrawerOpen') isDrawerOpen!: boolean;
  @Getter('currentContact') contact!: IContact;
  @Action(actionTypes.CLOSE_DRAWER) closeDrawer!: Function;

  showForm: boolean = false;

  @Watch('isDrawerOpen')
  isDrawerOpenChange(): void {
    if (this.isDrawerOpen) {
      this.showForm = true;
    } else {
      setTimeout(() => {
        this.showForm = false
      }, 0);
    }

    if (this.isDrawerOpen) {
      document.addEventListener('keyup', this.handleCloseDialog);
    } else {
      document.removeEventListener('keyup', this.handleCloseDialog);
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeIfNeed(false);
    }
  }

  closeIfNeed(flag: boolean): void {
    if (!flag) {
      this.closeDrawer();
    }
  }
}
