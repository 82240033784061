import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';
import { AutomationStepsState, RootState } from '@/interfaces/states';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IAutomationStep } from '@/interfaces/models/IAutomationStep';
import { AutomationStepService } from '@/http/services/automation-step.service';
import { IUpdateAutomationStep } from '@/interfaces/requests/IUpdateAutomationStep';
import { IReorderAutomationStep } from '@/interfaces/requests/IReorderAutomationStep';

export const state: AutomationStepsState = {
  automationSteps: [],
};

export const getters: GetterTree<AutomationStepsState, RootState> = {
  automationSteps: (state: AutomationStepsState) => state.automationSteps,
};

export const mutations: MutationTree<AutomationStepsState> = {
  [mutationTypes.SET_AUTOMATION_STEPS]: (state: AutomationStepsState, automationSteps: IAutomationStep[]) => {
    state.automationSteps = automationSteps;
  },
};

export const actions: ActionTree<AutomationStepsState, RootState> = {
  [actionTypes.FETCH_AUTOMATION_STEPS]: async ({commit}: ActionContext<AutomationStepsState, RootState>, automationId: number) => {
    const automationSteps = await AutomationStepService.fetchAutomationSteps(automationId);
    commit(mutationTypes.SET_AUTOMATION_STEPS, automationSteps);
  },

  [actionTypes.CREATE_AUTOMATION_STEP]: async ({dispatch, rootGetters}: ActionContext<AutomationStepsState, RootState>, payload: IAutomationStep) => {
    await AutomationStepService.createAutomationStep(payload);
    await dispatch(actionTypes.FETCH_AUTOMATION_STEPS, rootGetters.activeAutomation.id);
  },

  [actionTypes.UPDATE_AUTOMATION_STEP]: async ({dispatch, rootGetters}: ActionContext<AutomationStepsState, RootState>, payload: IUpdateAutomationStep) => {
    await AutomationStepService.updateAutomationStep(payload);
    await dispatch(actionTypes.FETCH_AUTOMATION_STEPS, rootGetters.activeAutomation.id);
  },

  [actionTypes.REMOVE_AUTOMATION_STEP]: async ({dispatch, rootGetters}: ActionContext<AutomationStepsState, RootState>, automationStepId: number) => {
    await AutomationStepService.removeAutomationStep(automationStepId);
    await dispatch(actionTypes.FETCH_AUTOMATION_STEPS, rootGetters.activeAutomation.id);
  },

  [actionTypes.REORDER_AUTOMATION_STEP]: async ({dispatch}: ActionContext<AutomationStepsState, RootState>, payload: IReorderAutomationStep) => {
    await AutomationStepService.reorderAutomationStep(payload);
    await dispatch(actionTypes.FETCH_AUTOMATION_STEPS);
  },

  [actionTypes.RESET_AUTOMATION_STEPS]: async ({commit}: ActionContext<AutomationStepsState, RootState>) => {
    commit(mutationTypes.SET_AUTOMATION_STEPS, []);
  },
};

export const automationSteps: Module<AutomationStepsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
