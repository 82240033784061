import IContact from "@/interfaces/models/contact.interface";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import {IStripePrice, StripeCurrency} from "@/interfaces/models/IStripePrice";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import { IStripeProduct } from "@/interfaces/models/IStripeProduct";
import IReport from "@/interfaces/models/report.interface";
import { UrlService } from '@/http/services/url.service';
import urlRegexSafe from 'url-regex-safe'
import {NotificationType} from "@/interfaces/models/INotification";
import store from '@/store';
import * as actionTypes from "@/store/action-types";
const emailRegexSafe = require('email-regex-safe');

const PHONE_NUMBER_LENGTH = 12;
let cacheUrls: {key: string, value: string}[] = [];

export const Helpers = {
  formatNumber(number: string | null): string {
    if (!number) {
      return '';
    }

    const replaceNumber = number
      .replace(/\D+/g, '')
      .trim();

    const pureNumber = replaceNumber
      .startsWith('+1')
      ? replaceNumber.slice(2)
      : replaceNumber.startsWith('1') ? replaceNumber.slice(1) : replaceNumber;

    return `+1 ${pureNumber.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3')}`;
  },

  formatNumberForApi(number: string): string {
    return `+1${number.replace(/[() -]/g, '')}`
  },

  truncateText(text: string, maxLength: number): string {
    return text?.length > maxLength ? `${text.slice(0, maxLength).trim()}...` : text;
  },

  getInitials(contact: IContact | IAgentAccount | null): string {
    if (!contact) {
      return '';
    }

    if (contact?.firstName?.length && contact?.lastName?.length) {
      return `${contact.firstName[0]}${contact.lastName[0]}`;
    } else if (contact.phoneNumber) {
      return contact.phoneNumber.slice(-2);
    } else {
      return '';
    }
  },

  getInitialReport(report: IReport | null): string {
    if (!report) {
      return '';
    }

    if (report?.fromNumber?.length && report?.toNumber?.length) {
      return ''; //`${report.fromNumber[0]}${report.toNumber[0]}`;
    } else {
      return '';
    }
  },

  getProfilePhoto(contact: IContact | IAgentAccount | null): string | boolean {
    if (!contact) {
      return false;
    }

    return contact.photo || false;
  },

  clearPhoneNumberFormatting(phoneNumber: string): string {
    const pureNumber = phoneNumber.startsWith('+1')
      ? phoneNumber === '+1' ? '' : phoneNumber
      : !phoneNumber.trim().length ? '' : `+1${phoneNumber.replace(/[() -]/g, '')}`;

    if (pureNumber.length !== PHONE_NUMBER_LENGTH) {
      return '';
    }

    return pureNumber;
  },

  formatPrice(price: number, currency: StripeCurrency): string {
    const formattedPrice = price.toFixed(2).toString().replace(/\./g, ',');
    const currencySuffix = currency === StripeCurrency.USD ? 'USD' : 'CAD';
    return `$${formattedPrice} ${currencySuffix}`;
  },

  getPlanDetails(businessAccount: IBusinessAccount, stripeProducts: IStripeProduct[], stripePrices: IStripePrice[]): string {
    if (businessAccount.isUnlimited) {
      return 'Unlimited';
    }

    if (businessAccount.isTrial) {
      return 'Textodog 14 Days Trial';
    }

    const currentProduct = stripeProducts.find(p => p.id === businessAccount.currentProductId);
    const currentPrice = stripePrices.find(p => p.id === businessAccount.currentPriceId);

    return `${currentProduct?.name} - ${currentPrice?.amount.toFixed(2).toString().replace('.', ',')}$/month`
  },

  async urlify(text: string): Promise<any> {
    const emails = text.match(emailRegexSafe());
    const mUrls = text.match(urlRegexSafe());

    const matchUrls = mUrls?.filter(url => {
      return (!url.includes('https://texto.dog') && !emails?.some(email => email.includes(url.replace(/[^a-zA-Z0-9.]/g, '')))); // allows only "." (textodog.com without "?", "/" etc.)
    })
    if(matchUrls?.length) {
      await store.dispatch(actionTypes.SHOW_NOTIFICATION, {
          text: 'Converting URL...',
          type: NotificationType.WARNING
        });
      const convertedUrls = await Promise.all(matchUrls.map(url => {
        if(isURL(url)) return UrlService.rewriteUrl({ url })
        else return UrlService.rewriteUrl({ url: "http://" + url })
      }));
      return {matchUrls, convertedUrls};
    }else{
      return null;
    }
  },
  isURL(str: string): boolean {
    var res = str.match(/(https?:\/\/)/g);
    return (res !== null)
  },
  async urlifyCom(text: string): Promise<string> {
    const urlRegex = /([^\s]+.com)/g;
    const mUrls = text.match(urlRegex);
    let replacedText = text;
    const matchUrls = mUrls?.filter(url => !isURL(url) && !url.includes('https://texto.dog'))
    if(matchUrls) {

      const convertedUrls = await Promise.all(matchUrls.map(url => UrlService.rewriteUrl({ url })));
      convertedUrls.every((shortUrl, index) => {
        const url = matchUrls[index];
        replacedText = text.replace(url, shortUrl);
      })
    }
    return replacedText;
  }
}
function isURL(url: string): boolean {
  var res = url.match(/(https?:\/\/)/g);
  return (res !== null)
}

