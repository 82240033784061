

























































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import ProfileSettings from "@/components/user-settings/ProfileSettings.vue";
import NotificationSettings from "@/components/user-settings/NotificationSettings.vue";
import ChangePasswordSettings from "@/components/user-settings/ChangePasswordSettings.vue";
import * as actionTypes from "@/store/action-types";
import {NotificationType} from "@/interfaces/models/INotification";

@Component({
  components: {ChangePasswordSettings, NotificationSettings, ProfileSettings}
})
export default class UserSettings extends Vue {
  @Prop({type: Boolean, required: true}) showDialog!: boolean;
  currentTabIndex: number = 0;
  isLoading: boolean = false;
  isProfile: boolean = true;
  isPassword: boolean = false;
  isCurrentPassword: boolean = false;
  isNewPassword: boolean = false;
  profileSettings: object | null = null;
  passwordSettings: object | null = null;

  @Emit('close-dialog')
  closeDialog(): void {
    this.currentTabIndex = 0
  }

  @Watch('showDialog')
  showDialogWatcher(): void {
    if (this.showDialog) {
      document.addEventListener('keyup', this.handleCloseDialog);
    } else {
      document.removeEventListener('keyup', this.handleCloseDialog);
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeDialog();
    }
  }

  changedCurrentPassword(value: string): void {
    this.isCurrentPassword = !!value;
  }

  changedNewPassword(value: string): void {
    this.isNewPassword = !!value;
  }

  changeToProfile(): void {
    this.isProfile = true;
    this.isPassword = false;
  }

  changeToPassword(): void {
    this.isProfile = false;
    this.isPassword = true;
  }

  async saveSettings(): Promise<void> {
    if(this.isProfile){
      this.profileSettings = this.saveProfileSettings();

      if (!this.profileSettings) {
        return;
      }
    }else{
      this.passwordSettings = this.savePasswordSettings();
    }

    const updateData = this.isProfile ? {
      ...this.profileSettings,
      activeNotification: false,
    } : {
      ...this.passwordSettings,
      activeNotification: false,
    };

    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.UPDATE_CURRENT_AGENT, updateData);
      this.isLoading = false;
      this.closeDialog();
      await this.$store.dispatch(actionTypes.FETCH_AGENTS);
    } catch (e) {
      this.isLoading = false;
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  saveProfileSettings(): object | null {
    const component = (this.$refs['profile-settings'] as ProfileSettings);

    if (!component) {
      return null;
    }

    const result: object | null = component.saveSettings();

    if (!result) {
      this.currentTabIndex = 0;
    }

    return result;
  }

  saveNotificationSettings(): object | null {
    const component = (this.$refs['notification-settings'] as ProfileSettings);

    if (!component) {
      return null;
    }

    return component.saveSettings();
  }

  savePasswordSettings(): object | null {
    const component = (this.$refs['change-password'] as ChangePasswordSettings);

    if (!component) {
      return null;
    }

    const result: object | null = component.saveSettings();

    if (!result) {
      this.currentTabIndex = 2;
    }

    return result;
  }
}
