












import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { INotification } from "@/interfaces/models/INotification";
import NotificationItem from "@/components/notification/NotificationItem.vue";

@Component({
  components: { NotificationItem }
})
export default class NotificationList extends Vue {
  @Getter notifications!: INotification[];
}
