




































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Stack, StackItem } from "vue-stack-grid";
import InfiniteLoading from "vue-infinite-loading";

@Component({
  name: "GiphyPopup",
  components: { Stack, StackItem, InfiniteLoading }
})
export default class GiphyPopup extends Vue {
  @Prop({ type: Boolean }) compose!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  showPopup: boolean = false;
  gf: GiphyFetch | null = null;
  gifs: any[] = [];
  term: string = "";
  timeoutId: number = 0;
  currentOffset = 0;
  isLoading: boolean = false;

  async mounted(): Promise<void> {
    this.gf = new GiphyFetch("8nPRj77X7fXnnZYtEEl5AeinzoCHoMkF");
    await this.loadTrending();
  }

  async loadTrending(append: boolean = false): Promise<void> {
    const data: any =
      (await this.gf?.trending({ offset: this.currentOffset * 15, limit: 15 })) || [];
    const filteredData =
      data?.data?.filter(({ images }: any) => images.fixed_width.size / 1000 < 500) || [];
    if (append) {
      this.gifs = this.gifs.concat(filteredData);
    } else {
      this.gifs = filteredData;
    }
  }

  async searchGifs(term: string, append: boolean = false) {
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(async () => {
      if (!append) {
        this.currentOffset = 0;
      }

      if (!this.term) {
        return this.loadTrending();
      }

      const data: any =
        (await this.gf?.search(term, { limit: 15, offset: this.currentOffset * 15 })) || [];
      const filteredData =
        data?.data?.filter(({ images }: any) => images.fixed_width.size / 1000 < 500) || [];
      if (append) {
        this.gifs = this.gifs.concat(filteredData);
      } else {
        this.gifs = filteredData;
      }
    }, 1000);
  }

  async handleScroll(event: any): Promise<void> {
    if (Math.abs(event.target.scrollTop - event.target.scrollHeight) < 300 && !this.isLoading) {
      this.isLoading = true;

      await this.loadMoreHandler();

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
  }

  async loadMoreHandler(): Promise<void> {
    this.currentOffset = this.currentOffset + 1;

    if (this.term) {
      await this.searchGifs(this.term, true);
    } else {
      await this.loadTrending(true);
    }
  }

  handleClickOutside(): void {
    if (this.showPopup) {
      this.showPopup = false;
    }
  }

  @Emit("select-gif")
  selectGIF(url: string, title: string): { url: string; title: string } {
    this.showPopup = false;
    return { url, title };
  }
}
