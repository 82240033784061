





















import { Component, Vue, Watch } from "vue-property-decorator";
import Header from "@/components/header/Header.vue";
import Vuetify from "vuetify";
import NotificationList from "@/components/notification/NotificationList.vue";

@Component({
  vuetify: new Vuetify(),
  components: {
    NotificationList,
    Header
  }
})
export default class App extends Vue {
  isWhiteBackground: boolean = false;
  isInited: boolean = false;

  @Watch("$route", { immediate: true })
  observeBackground(): void {
    this.isWhiteBackground = this.$route.meta?.whiteBackground;
  }

  mounted(): void {
    this.$nextTick(() => (this.isInited = true));
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }
}
