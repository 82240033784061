import API from '@/http/http-client';
import { IEndWebhook } from '@/interfaces/models/IEndWebhook';
import { ICreateEndWebhook } from '@/interfaces/requests/ICreateEndWebhook';
import { IUpdateEndWebhook } from '@/interfaces/requests/IUpdateEndWebhook';

export const EndWebhooksService = {

  async fetchEndWebhooks(automationId: number): Promise<IEndWebhook[]> {
    const {data} = await API.get(`/dog/end-automation-webhooks`, {
      params: {automationId: automationId}
    });
    return data;
  },

  async createEndWebhook(payload: ICreateEndWebhook): Promise<IEndWebhook> {
    const {data} = await API.post(`/dog/end-automation-webhooks`, payload);
    return data;
  },

  async fetchEndWebhook(id: number): Promise<IEndWebhook> {
    const {data} = await API.get(`/dog/end-automation-webhooks/${id}`);
    return data;
  },

  async updateEndWebhook(payload: IUpdateEndWebhook): Promise<IEndWebhook> {
    const url = `/dog/end-automation-webhooks/${payload.id}`;
    delete payload.id;
    const {data} = await API.patch(url, payload);
    return data;
  },

  async removeEndWebhook(id: number): Promise<void> {
    await API.delete(`/dog/end-automation-webhooks/${id}`);
  },
}
