import { IAutomationStep } from '@/interfaces/models/IAutomationStep';
import API from '@/http/http-client';
import { IReorderAutomationStep } from '@/interfaces/requests/IReorderAutomationStep';
import { IUpdateAutomationStep } from '@/interfaces/requests/IUpdateAutomationStep';

export const AutomationStepService = {
  async fetchAutomationSteps(automationId: number): Promise<IAutomationStep[]> {
    const {data} = await API.get(`/dog/automation-steps/`, {
      params: {automationId}
    });
    return data;
  },

  async fetchAutomationStep(automationStepId: number): Promise<IAutomationStep> {
    const {data} = await API.get(`/dog/automation-steps/${automationStepId}`);
    return data;
  },

  async createAutomationStep(payload: IAutomationStep): Promise<IAutomationStep> {
    const {data} = await API.post(`/dog/automation-steps/`, payload);
    return data;
  },

  async updateAutomationStep(payload: IUpdateAutomationStep): Promise<IAutomationStep> {
    const {data} = await API.patch(`/dog/automation-steps/${payload.automationStepId}`, payload.updateData);
    return data;
  },

  async removeAutomationStep(automationStepId: number): Promise<void> {
    await API.delete(`/dog/automation-steps/${automationStepId}`);
  },

  async reorderAutomationStep(payload: IReorderAutomationStep): Promise<IAutomationStep> {
    const {data} = await API.patch(`/dog/automation-steps/${payload.automationStepId}`, {
      position: payload.position,
    });
    return data;
  },
};
