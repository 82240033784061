import { CustomFieldState, RootState } from '@/interfaces/states';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import * as mutationTypes from "@/store/mutation-types";
import * as actionTypes from "@/store/action-types";
import { ICustomField } from '@/interfaces/models/ICustomField';
import { CustomFieldService } from '@/http/services/custom-field.service';
import { ICreateCustomField } from '@/interfaces/requests/ICreateCustomField';
import { IUpdateCustomField } from '@/interfaces/requests/IUpdateCustomField';

export const state: CustomFieldState = {
  customFields: [],
};

export const getters: GetterTree<CustomFieldState, RootState> = {
  customFields: (state: CustomFieldState) => state.customFields,
};

export const mutations: MutationTree<CustomFieldState> = {
  [mutationTypes.SET_CUSTOM_FIELDS]: (state: CustomFieldState, customFields: ICustomField[]) => {
    state.customFields = customFields;
  },
};

export const actions: ActionTree<CustomFieldState, RootState> = {
  [actionTypes.FETCH_CUSTOM_FIELDS]: async ({commit}: ActionContext<CustomFieldState, RootState>) => {
    const customFields = await CustomFieldService.fetchCustomFields();
    commit(mutationTypes.SET_CUSTOM_FIELDS, customFields);
  },

  [actionTypes.CREATE_CUSTOM_FIELD]: async ({dispatch}: ActionContext<CustomFieldState, RootState>, payload: ICreateCustomField) => {
    await CustomFieldService.createCustomField(payload);
    await dispatch(actionTypes.FETCH_CUSTOM_FIELDS);
  },

  [actionTypes.UPDATE_CUSTOM_FIELD]: async ({dispatch}: ActionContext<CustomFieldState, RootState>, payload: IUpdateCustomField) => {
    await CustomFieldService.updateCustomField(payload);
    await dispatch(actionTypes.FETCH_CUSTOM_FIELDS);
  },

  [actionTypes.REMOVE_CUSTOM_FIELD]: async ({dispatch}: ActionContext<CustomFieldState, RootState>, customFieldId: number) => {
    await CustomFieldService.removeCustomField(customFieldId);
    await dispatch(actionTypes.FETCH_CUSTOM_FIELDS);
  },
};

export const customFields: Module<CustomFieldState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
