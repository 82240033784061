import API from "@/http/http-client";
import ITextodealOffer from "@/interfaces/models/textodeal-offer.interface";

export const TextodealOffersService = {
  async fetchOffers(): Promise<ITextodealOffer[]> {
    const {data} = await API.get('/deal/offers');
    return data;
  },

  async createOffer(offer: Partial<ITextodealOffer>): Promise<ITextodealOffer> {
    const {data} = await API.post('/deal/offers', offer);
    return data;
  },

  async updateOffer(offer: ITextodealOffer): Promise<ITextodealOffer> {
    const {data} = await API.patch(`/deal/offers/${offer.id}`, offer);
    return data;
  },

  async removeOffer(offerId: number): Promise<void> {
    await API.delete(`/deal/offers/${offerId}`);
  },
}
