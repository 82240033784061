




























import {Component, Vue, Prop} from 'vue-property-decorator';
import IAgentAccount from "../../interfaces/models/agent-account.interface";
import IContact from "../../interfaces/models/contact.interface";
import {Helpers} from "../utils/helpers.js";

@Component
export default class ContactAvatar extends Vue {
  @Prop({required: true}) contact!: IAgentAccount | IContact;
  @Prop({type: String, default: '#4D7CFE'}) color!: string;
  @Prop({type: Number, default: 36}) size!: number;
  @Prop({type: Boolean, default: false}) botPhoto!: boolean;

  get profilePhoto(): string | boolean {
    if (this.botPhoto) {
      return '/img/default-photo.png';
    }

    return Helpers.getProfilePhoto(this.contact);
  }

  get initials(): string {
    return Helpers.getInitials(this.contact);
  }
}
