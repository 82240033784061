import { MessageService } from "@/http/services/message.service";
import { MessagesStateInterface, RootState } from "@/interfaces/states";
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";
import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import IMessage from "@/interfaces/models/message.interface";
import { PushNotificationService } from "@/common/services/push-notification.service";

export const state: MessagesStateInterface = {
  messages: [],
  isMessagesFetched: false
};

export const getters: GetterTree<MessagesStateInterface, RootState> = {
  messages: (state: MessagesStateInterface) => state.messages,
  isMessagesFetched: (state: MessagesStateInterface) => state.isMessagesFetched
};

export const mutations: MutationTree<MessagesStateInterface> = {
  [mutationTypes.SET_MESSAGES]: (state: MessagesStateInterface, messages: IMessage[]) => {
    state.messages = messages;
  },

  [mutationTypes.CONCAT_MESSAGES]: (state: MessagesStateInterface, messages: IMessage[]) => {
    state.messages = state.messages.concat(...messages);
  },

  [mutationTypes.CONCAT_NEW_MESSAGE]: (state: MessagesStateInterface, message: IMessage) => {
    if (!state.messages.find(msg => msg.id === message.id)) {
      state.messages = state.messages.concat(message);
    }
  }
};

export const actions: ActionTree<MessagesStateInterface, RootState> = {
  [actionTypes.FETCH_MESSAGES]: async (
    { commit, state }: ActionContext<MessagesStateInterface, RootState>,
    conversationId: number
  ) => {
    state.isMessagesFetched = false;
    const messages = await MessageService.fetchMessages(conversationId);
    commit(mutationTypes.SET_MESSAGES, messages.reverse());
    state.isMessagesFetched = true;
  },

  [actionTypes.SEND_MESSAGE]: async (
    { commit }: ActionContext<MessagesStateInterface, RootState>,
    message: IMessage
  ) => {
    const newMessage = await MessageService.sendMessage(message);
    commit(mutationTypes.CONCAT_NEW_MESSAGE, newMessage);
    commit(mutationTypes.UPDATE_LATEST_REPORT, true);
    commit(mutationTypes.UPDATE_LATEST_MESSAGE, newMessage);
  },

  [actionTypes.MESSAGE_RECEIVED]: async (
    { commit, dispatch, rootState }: ActionContext<MessagesStateInterface, RootState>,
    message: IMessage
  ) => {
    const messageDepartmentId = message.department.id;
    const agentDepartments = rootState?.auth?.agent?.departments?.map(({ id }) => id) || [];
    if (message.contact && agentDepartments.includes(messageDepartmentId)) {
      await PushNotificationService.showPushNotification(
        `${message.contact?.firstName} ${message.contact?.lastName}`,
        message.text,
        message.contact?.photo || "",
        message.media,
        message.conversationId
      );
    }

    const isExist = [
      ...rootState.conversation.openedConversations,
      ...rootState.conversation.closedConversations
    ].find(c => c.id === message.conversationId);
    if (!isExist) {
      await dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: true, currentPage: 1 });
    }

    if (message.conversationId === rootState.conversation.currentConversation?.id) {
      commit(mutationTypes.CONCAT_NEW_MESSAGE, message);
    }
  }
};

export const messages: Module<MessagesStateInterface, RootState> = {
  state,
  getters,
  mutations,
  actions
};
