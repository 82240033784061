





























































import {Component, Vue, Watch} from 'vue-property-decorator';
import * as actionTypes from "@/store/action-types";
import {NotificationType} from "@/interfaces/models/INotification";

@Component
export default class ChangePasswordSettings extends Vue {
  showCurrentPassword: boolean = false;
  showNewPassword: boolean = false;
  currentPasswordErrorMessage: string = '';
  newPasswordErrorMessage: string = '';

  currentPassword: string = '';
  newPassword: string = '';

  isLoading: boolean = false;

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  @Watch('currentPassword')
  observeCurrentPassword(): void {
    this.$emit('current-password-changed', this.currentPassword);
  }

  @Watch('newPassword')
  observeNewPassword(): void {
    this.$emit('new-password-changed', this.newPassword);

  }

  saveSettings(): object | null {
    return {
      currentPassword: this.currentPassword,
      newPassword: this.newPassword
    };
  }

  async updatePassword(): Promise<void> {
    try {
      const updateData = this.saveSettings();

      if (updateData) {
        this.isLoading = true;
        await this.$store.dispatch(actionTypes.UPDATE_CURRENT_AGENT, updateData);
        this.$emit('changed');
      }
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }
}
