import {EditDrawerState, RootState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";

export const state: EditDrawerState = {
  contact: null,
  isOpen: false,
  insideConversation: false,
};

export const getters: GetterTree<EditDrawerState, RootState> = {
  isDrawerOpen: (state: EditDrawerState) => state.isOpen,
  getContact: (state: EditDrawerState) => state.contact,
  insideConversation: (state: EditDrawerState) => state.insideConversation,
};

export const mutations: MutationTree<EditDrawerState> = {
  [mutationTypes.SET_CONTACT_DRAWER_STATE]: (state: EditDrawerState, value: boolean) => {
    state.isOpen = value;
  },

  [mutationTypes.SET_CONVERSATION_STATE]: (state: EditDrawerState, value: boolean) => {
    state.insideConversation = value;
  }
};

export const actions: ActionTree<EditDrawerState, RootState> = {
  [actionTypes.OPEN_DRAWER_TO_EDIT]: async ({commit, dispatch}: ActionContext<EditDrawerState, RootState>, {contactId, insideConversation = true}: {contactId: number, insideConversation: boolean}) => {
    await dispatch(actionTypes.FETCH_CONTACT_BY_ID, contactId, {root: true});
    commit(mutationTypes.SET_CONTACT_DRAWER_STATE, true);
    commit(mutationTypes.SET_CONVERSATION_STATE, insideConversation);
  },

  [actionTypes.OPEN_DRAWER_TO_CREATE]: async ({commit, state}: ActionContext<EditDrawerState, RootState>) => {
    commit(mutationTypes.SET_CONTACT_DRAWER_STATE, true);

    if (!state.insideConversation) {
      commit(mutationTypes.SET_CURRENT_CONTACT, null, {root: true})
    }
  },

  [actionTypes.CLOSE_DRAWER]: ({commit}: ActionContext<EditDrawerState, RootState>) => {
    commit(mutationTypes.SET_CONTACT_DRAWER_STATE, false);
    commit(mutationTypes.SET_CONVERSATION_STATE, false);
  }
};

export const editDrawer: Module<EditDrawerState, RootState> = {
  state,
  getters,
  mutations,
  actions
};
