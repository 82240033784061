import IBusinessAccount from "@/interfaces/models/business-account.interface";
import { IDepartment } from '@/interfaces/models/IDepartment';

export default interface IAgentAccount {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  activeNotification: boolean;
  role: AgentRole;
  photo: string | null;
  isMain: boolean;
  businessAccount: IBusinessAccount;
  isActive: boolean;
  status: AgentStatus;
  departments: IDepartment[];
}

export enum AgentRole {
  ADMINISTRATOR = 'Administrator',
  STANDARD = 'Standard',
}

export enum AgentStatus {
  ACTIVE = 'success',
  PENDING = 'pending',
  INACTIVE = 'inactive',
  FAILURE = 'failure',
}
