import { ContactsStateInterface, RootState } from "@/interfaces/states";
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";
import * as mutationTypes from "@/store/mutation-types";
import * as actionTypes from "@/store/action-types";
import IContact from "@/interfaces/models/contact.interface";
import { ContactsService } from "@/http/services/contacts.service";
import { NotificationType } from "@/interfaces/models/INotification";

export const state: ContactsStateInterface = {
  contacts: [],
  allContacts: [],
  contacts_ct: 0,
  currentContact: null
};

export const getters: GetterTree<ContactsStateInterface, RootState> = {
  contacts: (state: ContactsStateInterface) => state.contacts,
  allContacts: (state: ContactsStateInterface) => state.allContacts,
  contacts_ct: (state: ContactsStateInterface) => state.contacts_ct,
  // .sort((a, b) => `${a.firstName}`.toLowerCase() >`${b.firstName}`.toLowerCase() ? 1 : -1),
  currentContact: (state: ContactsStateInterface) => state.currentContact
};

export const mutations: MutationTree<ContactsStateInterface> = {
  [mutationTypes.SET_CONTACTS]: (state: ContactsStateInterface, contacts: IContact[]) => {
    state.contacts = contacts;
  },

  [mutationTypes.SET_ALL_CONTACTS]: (state: ContactsStateInterface, allContacts: IContact[]) => {
    state.allContacts = allContacts;
  },

  [mutationTypes.SET_CONTACTS_CT]: (state: ContactsStateInterface, contacts_ct: number) => {
    state.contacts_ct = contacts_ct;
  },

  [mutationTypes.SET_CURRENT_CONTACT]: (
    state: ContactsStateInterface,
    contact: IContact | null
  ) => {
    state.currentContact = contact;
  },

  [mutationTypes.UPDATE_CONTACT]: (state: ContactsStateInterface, updatedContact: IContact) => {
    state.contacts = state.contacts.map((contact: IContact) =>
      contact.id === updatedContact.id ? updatedContact : contact
    );
  }
};

export const actions: ActionTree<ContactsStateInterface, RootState> = {
  [actionTypes.FETCH_CONTACTS]: async (
    { commit }: ActionContext<ContactsStateInterface, RootState>,
    payload: any
  ) => {
    const { data, total } = await ContactsService.fetchContacts(payload);
    commit(mutationTypes.SET_CONTACTS, data);
    commit(mutationTypes.SET_CONTACTS_CT, Number(total));
  },

  [actionTypes.FETCH_ALL_CONTACTS]: async ({
    commit
  }: ActionContext<ContactsStateInterface, RootState>) => {
    const { data } = await ContactsService.fetchContacts({ perPage: 0 });
    commit(mutationTypes.SET_ALL_CONTACTS, data);
  },

  [actionTypes.FETCH_CONTACT_BY_ID]: async (
    { commit }: ActionContext<ContactsStateInterface, RootState>,
    id: number
  ) => {
    const contact = await ContactsService.getContactBydId(id);
    commit(mutationTypes.SET_CURRENT_CONTACT, contact);
  },

  [actionTypes.UPDATE_CONTACT]: async (
    { commit, rootGetters, dispatch }: ActionContext<ContactsStateInterface, RootState>,
    contact: IContact
  ) => {
    const updatedContact = await ContactsService.updateContact(contact);
    commit(mutationTypes.UPDATE_CONTACT, updatedContact);
    commit(mutationTypes.SET_CURRENT_CONTACT, updatedContact);

    if (rootGetters.insideConversation && rootGetters?.currentConversation?.id) {
      await dispatch(actionTypes.FETCH_MESSAGES, rootGetters.currentConversation.id);
      await dispatch(actionTypes.FETCH_CURRENT_CONVERSATION, rootGetters.currentConversation.id);
    }
  },

  [actionTypes.CREATE_CONTACT]: async (
    { commit, dispatch }: ActionContext<ContactsStateInterface, RootState>,
    contact: IContact
  ) => {
    await ContactsService.createContact(contact);
    await dispatch(actionTypes.FETCH_CONTACTS);
  },

  [actionTypes.REMOVE_CONTACT]: async (
    { commit, dispatch }: ActionContext<ContactsStateInterface, RootState>,
    id
  ) => {
    const { data } = await ContactsService.removeContact(id);
    // console.log('REMOVE_CONTACT');
    // console.log(data);
  },

  [actionTypes.REMOVE_CONTACTS]: async (
    { commit, dispatch }: ActionContext<ContactsStateInterface, RootState>,
    ids: number[]
  ) => {
    const { data } = await ContactsService.removeContacts(ids);

    if (data.errorContacts) {
      await dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: data.errorContacts[0],
        type: NotificationType.ERROR
      });
    } else {
      await dispatch(actionTypes.FETCH_CONTACTS);
    }
  },

  [actionTypes.REMOVE_TAG_FOR_MULTIPLE_CONTACTS]: async (
    { commit, dispatch }: ActionContext<ContactsStateInterface, RootState>,
    payload: object
  ) => {
    await ContactsService.removeTagForMultipleContacts(payload);
    await dispatch(actionTypes.FETCH_CONTACTS);
  },

  [actionTypes.ADD_TAG_FOR_MULTIPLE_CONTACTS]: async (
    { commit, dispatch }: ActionContext<ContactsStateInterface, RootState>,
    payload: object
  ) => {
    await ContactsService.addTagForMultipleContacts(payload);
    await dispatch(actionTypes.FETCH_CONTACTS);
  }
};

export const contacts: Module<ContactsStateInterface, RootState> = {
  state,
  getters,
  mutations,
  actions
};
