export interface INotification {
  id: number;
  text: string;
  type: NotificationType;
}

export enum NotificationType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}
