import Vue from 'vue';
import Vuex, { ActionContext, StoreOptions } from 'vuex';

import * as actionTypes from "@/store/action-types";

import { messages } from "@/store/modules/messages";
import { contacts } from "@/store/modules/contacts";
import { reports } from "@/store/modules/reports";
import { RootState } from "@/interfaces/states";
import { header } from "@/store/modules/header";
import { editDrawer } from "@/store/modules/edit-drawer";
import { widgets } from "@/store/modules/widgets";
import { reply } from "@/store/modules/reply";
import { billing } from "@/store/modules/billing";
import { auth } from "@/store/modules/auth";
import { notification } from "@/store/modules/notification";
import { conversation } from "@/store/modules/conversation";
import { tags } from "@/store/modules/tags";
import { identifiers } from "@/store/modules/identifiers";
import { businessAccount } from "@/store/modules/business-account";
import { agents } from "@/store/modules/agents";
import { stripeProduct } from "@/store/modules/stripe-product";
import { departments } from '@/store/modules/departments';
import { automations } from '@/store/modules/automations';
import { customFields } from '@/store/modules/custom-fields';
import { automationSteps } from '@/store/modules/automation-steps';
import { textodealOffers } from "@/store/modules/textodeal-offer";
import { textodealProviders } from "@/store/modules/textodeal-provider";
import { endWebhooks } from '@/store/modules/end-webhooks';

Vue.use(Vuex);

// initial state
const initialState = {
  messages: [],
  contacts: []
}
const store: StoreOptions<RootState> = {
  modules: {
    messages,
    contacts,
    reports,
    header,
    editDrawer,
    widgets,
    reply,
    billing,
    auth,
    snackbar: notification,

    conversation,
    tags,
    identifiers,
    businessAccount,
    agents,
    stripeProduct,
    departments,
    automations,
    customFields,
    automationSteps,
    textodealOffers,
    textodealProviders,
    endWebhooks
  },
  mutations: {
    reset (state: any) {
      Object.assign(state, initialState)
    }
  },
  actions: {
    [actionTypes.RESET]: ({commit}) => {
      commit('reset');
    }
  }
};

export default new Vuex.Store(store);
