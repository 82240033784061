






























import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import ImageCropperModal from "@/common/reusable-components/ImageCropperModal.vue";
import imageLoadingHelper from '@/common/utils/image-loading-helper';
import {NotificationType} from "@/interfaces/models/INotification";
import * as actionTypes from '@/store/action-types';

@Component({
  components: {ImageCropperModal}
})
export default class ImageDropArea extends Vue {
  image: string | null = null;
  showCropper: boolean = false;
  croppedImage: string = '';
  allowEdit: boolean = true;

  @Prop() currentImage!: string;
  @Prop() imageShape!: string;
  @Prop() imageQuality!: number;

  @Emit('select-file')
  selectFile(image: string): string | null {
    this.croppedImage = image;
    this.showCropper = false;
    return image;
  }

  @Watch('currentImage', {immediate: true})
  changePhoto(): void {
    if (this.currentImage) {
      this.allowEdit = false;
    }

    this.croppedImage = this.currentImage;
    this.image = this.currentImage;
  }

  get dropAreaText(): string {
    return this.$vuetify.breakpoint.xs ? 'upload image' : 'select or drag image';
  }

  openFileSelect(): void {
    if (!this.image) {
      (this.$refs.fileInput as HTMLInputElement).click();
    }
  }

  onDrop(e: HTMLFormElement) {
    e.stopPropagation();
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.processFile({target: {files}});
  }

  async processFile(event: any): Promise<void> {
    try {
      this.image = await imageLoadingHelper(event);
      this.showCropper = true;
      this.allowEdit = true;
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  removeFile(e: Event) {
    e.stopPropagation();
    this.image = null;
    this.selectFile('');
  }

  closeCropper(): void {
    this.showCropper = false;
    if (!this.croppedImage) {
      this.image = '';
      (this.$refs.imageForm as HTMLFormElement).reset();
    }
  }
}
