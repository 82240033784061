import { IDepartment } from '@/interfaces/models/IDepartment';
import API from '@/http/http-client';
import { ICreateDepartment } from '@/interfaces/requests/ICreateDepartment';

export const DepartmentService = {
  async fetchDepartments(): Promise<IDepartment[]> {
    const {data} = await API.get('/dog/departments');
    return data;
  },

  async createDepartment(payload: ICreateDepartment): Promise<IDepartment> {
    const {data} = await API.post('/dog/departments', payload);
    return data;
  },

  async updateDepartment(departmentId: number, payload: Partial<ICreateDepartment>): Promise<IDepartment> {
    const {data} = await API.patch(`/dog/departments/${departmentId}`, payload);
    return data;
  },

  async removeDepartment(departmentId: number): Promise<void> {
    await API.delete(`/dog/departments/${departmentId}`);
  },
}
