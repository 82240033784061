

























































































import { Vue, Component, Watch } from "vue-property-decorator";
import Icon from "@/common/reusable-components/Icon.vue";
import Notification from "@/components/header/Notification.vue";
import NewItemPopover from "@/components/header/NewItemPopover.vue";
import ProfileAvatar from "@/components/header/ProfileAvatar.vue";
import NavigationDrawer from "@/components/header/NavigationDrawer.vue";
import { Getter } from "vuex-class";
import EditContactDrawer from "@/components/contacts/EditContactDrawer.vue";
import IBusinessAccount from "@/interfaces/models/business-account.interface";

@Component({
  components: {
    EditContactDrawer,
    NavigationDrawer,
    ProfileAvatar,
    NewItemPopover,
    Notification,
    Icon
  }
})
export default class Header extends Vue {
  @Getter("getHeaderTitle", { namespace: "header" }) headerTitle!: string;
  @Getter("getHeaderBackLink", { namespace: "header" }) backLink!: string;
  @Getter("getHeaderCountOfSelectedContacts", { namespace: "header" })
  countOfSelectedContacts!: number;
  @Getter currentBusinessAccount!: IBusinessAccount;

  isMobile: boolean = false;
  hideOnMobile: boolean = false;
  hideOnDesktop: boolean = false;
  isLoading: boolean = false;

  @Watch("$vuetify.breakpoint.xs", { immediate: true })
  observeMobile(): void {
    this.isMobile = this.$vuetify.breakpoint.xs;
  }

  @Watch("$route", { immediate: true })
  routeObserver(): void {
    this.hideOnMobile = this.$route.meta?.hideHeaderOnMobile;
    this.hideOnDesktop = this.$route.meta?.hideHeaderOnDesktop;
  }

  get isHomePage(): boolean {
    return this.$route.path.startsWith("/inbox");
  }

  isSuperAdmin(): boolean {
    return this.currentBusinessAccount?.isSuperAdmin ?? false;
  }
}
