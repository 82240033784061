import { AuthState, RootState } from "@/interfaces/states";
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AuthService } from "@/http/services/auth.service";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import { AgentsService } from "@/http/services/agents.service";
import jwt_decode from "jwt-decode";
import { IAuthToken } from "@/interfaces/models/auth-token.interface";
import { MessageService } from "@/http/services/message.service";
import IMessage from "@/interfaces/models/message.interface";

export const state: AuthState = {
  token: "",
  agent: null,
  loggedInAsAdmin: false
};

export const getters: GetterTree<AuthState, RootState> = {
  isAuth: (state: AuthState) => !!state.agent,
  agent: (state: AuthState) => state.agent,
  token: (state: AuthState) => state.token,
  loggedInAsAdmin: (state: AuthState) => state.loggedInAsAdmin
};

export const mutations: MutationTree<AuthState> = {
  [mutationTypes.SET_TOKEN]: (state: AuthState, token: string) => {
    state.token = token;
  },

  [mutationTypes.SET_AGENT]: (state: AuthState, agent: IAgentAccount) => {
    state.agent = agent;
  },

  [mutationTypes.RESET_AUTH]: (state: AuthState) => {
    state.token = "";
    state.agent = null;
  },

  [mutationTypes.SET_AS_ADMIN]: (state: AuthState, asAdmin: boolean) => {
    state.loggedInAsAdmin = asAdmin;
  }
};

export const actions: ActionTree<AuthState, RootState> = {
  [actionTypes.RESTORE_AUTH]: async ({
    commit,
    state,
    dispatch
  }: ActionContext<AuthState, RootState>) => {
    if (state.token) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      return await dispatch(actionTypes.SIGN_OUT);
    }

    commit(mutationTypes.SET_TOKEN, JSON.parse(token));

    await dispatch(actionTypes.FETCH_CURRENT_AGENT);
    await dispatch(actionTypes.FETCH_BUSINESS_ACCOUNT_BY_ID);
    await MessageService.subscribeToPusherUpdate((data: IMessage) => {
      console.log("received pusher message", data);
      // const msg: any = data;
      // const agent: any = store.getters['agent'];
      // console.log("agent : ", agent.id)
      // console.log("msg : ", msg.receiver?.id)
      dispatch(actionTypes.UPDATE_CONVERSATION_LATEST_MESSAGE, data);
    });
  },

  [actionTypes.LOGIN]: async (
    { commit, dispatch }: ActionContext<AuthState, RootState>,
    payload: { email: string; password: string }
  ) => {
    const token = await AuthService.login(payload.email, payload.password);
    commit(mutationTypes.SET_TOKEN, token);
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("loggedInAsAdmin", "false");
    await dispatch(actionTypes.FETCH_CURRENT_AGENT);
    await dispatch(actionTypes.FETCH_BUSINESS_ACCOUNT_BY_ID);
    await MessageService.subscribeToPusherUpdate((data: IMessage) => {
      dispatch(actionTypes.UPDATE_CONVERSATION_LATEST_MESSAGE, data);
    });
  },

  [actionTypes.LOGIN_AS_ADMIN]: async (
    { commit, dispatch }: ActionContext<AuthState, RootState>,
    payload: { originalBusinessAccountId: number; targetBusinessAccountId: number }
  ) => {
    const token = await AuthService.login_as_admin(
      payload.originalBusinessAccountId,
      payload.targetBusinessAccountId
    );
    commit(mutationTypes.SET_TOKEN, token);
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("loggedInAsAdmin", "true");
    await dispatch(actionTypes.FETCH_CURRENT_AGENT);
    await dispatch(actionTypes.FETCH_BUSINESS_ACCOUNT_BY_ID);
    commit(mutationTypes.SET_AS_ADMIN, true);
  },

  [actionTypes.LOGOUT_FROM_ADMIN]: async ({
    commit,
    dispatch
  }: ActionContext<AuthState, RootState>) => {
    const token = await AuthService.logout_from_admin();
    commit(mutationTypes.SET_TOKEN, token);
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("loggedInAsAdmin", "false");
    await dispatch(actionTypes.FETCH_CURRENT_AGENT);
    await dispatch(actionTypes.FETCH_BUSINESS_ACCOUNT_BY_ID);
    commit(mutationTypes.SET_AS_ADMIN, false);
  },

  [actionTypes.FETCH_CURRENT_AGENT]: async ({
    commit,
    state
  }: ActionContext<AuthState, RootState>) => {
    const decodedToken: IAuthToken = jwt_decode(state.token);
    const agent = await AgentsService.fetchAgent(decodedToken.sub.id);
    if (localStorage.getItem("loggedInAsAdmin") == "true") {
      commit(mutationTypes.SET_AS_ADMIN, true);
    } else {
      commit(mutationTypes.SET_AS_ADMIN, false);
    }
    commit(mutationTypes.SET_AGENT, agent);
  },

  [actionTypes.UPDATE_CURRENT_AGENT]: async (
    { commit, state, rootGetters, dispatch }: ActionContext<AuthState, RootState>,
    updateData: object
  ) => {
    const updatedAgent = await AgentsService.updateAgent(state.agent!.id, updateData);
    commit(mutationTypes.SET_AGENT, updatedAgent);

    if (rootGetters.currentConversation) {
      await dispatch(actionTypes.FETCH_MESSAGES, rootGetters.currentConversation.id);
    }
  },

  [actionTypes.SIGN_OUT]: ({ commit }: ActionContext<AuthState, RootState>) => {
    localStorage.removeItem("token");
    commit(mutationTypes.RESET_AUTH);
    MessageService.unsubscribeFromPusherUpdate();
  }
};

export const auth: Module<AuthState, RootState> = {
  state,
  getters,
  mutations,
  actions
};
