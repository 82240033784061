









































import {Component, Prop, Vue} from 'vue-property-decorator';
import ProfileSettings from "@/components/user-settings/ProfileSettings.vue";
import ChangePasswordSettings from "@/components/user-settings/ChangePasswordSettings.vue";

@Component({
  components: {ChangePasswordSettings, ProfileSettings}
})
export default class UserSettingsMobile extends Vue {
  @Prop({type: Boolean, required: true}) showPopup!: boolean;
  currentMenu: 'None' | 'UserSettings' | 'PasswordSettings' = 'None';

  get title(): string {
    switch (this.currentMenu) {
      case "UserSettings":
        return 'Profile';
      case "PasswordSettings":
        return 'Change Password';
      default:
        return 'My Settings'
    }
  }

  back(): void {
    if (this.currentMenu === 'None') {
      this.$emit('close');
    } else {
      this.currentMenu = 'None';
    }
  }
}
