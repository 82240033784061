import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'
import App from './App.vue';
import './registerServiceWorker';
import '@/common/reusable-components'
import router from './router';
import store from './store';
import '@/plugins/vuetify';
import '@/plugins/additional-plugins';
import * as actionTypes from "@/store/action-types";
import {NotificationType} from "@/interfaces/models/INotification";

Vue.config.productionTip = false;

if (location.protocol !== 'https:') {
  if(location.hostname !== 'localhost') {
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
  }
}

store.dispatch(actionTypes.RESTORE_AUTH)
  .then(() => {
    if (store.getters['currentBusinessAccount'].isExpired) {
      router.push('/settings/billing?tab=change-plan');
      if (store.getters['currentBusinessAccount'].expiredMessage) {
        store.dispatch(actionTypes.SHOW_NOTIFICATION, {
          text: store.getters['currentBusinessAccount'].expiredMessage,
          type: NotificationType.ERROR
        });
      }
    }
  })
  .catch(async e => {
    if (e.code === 'auth-token-expired') {
      await store.dispatch(actionTypes.SIGN_OUT);
      store.dispatch('reset');
      await router.push('/sign-in');
    }
  }).finally(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');

})
