import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import store from "@/store";
import RouteMeta from "@/interfaces/route-meta.interface";
import { FETCH_CURRENT_AGENT } from "@/store/action-types";
import { ROLES } from "@/common/utils/roles";

Vue.use(VueRouter);

const adminGuard = async (to: Route, from: Route, next: NavigationGuardNext) => {
  await store.dispatch(FETCH_CURRENT_AGENT);
  if (store.getters.agent.role === ROLES.ADMINISTRATOR) {
    next();
  } else {
    next("/");
  }
};

const billingInfoGuard = async (to: Route, from: Route, next: NavigationGuardNext) => {
  if (isHasAllRequiredParamsForBillingInfo(to)) {
    next();
  } else {
    next("/sign-up");
  }
};

const signUpGuard = async (to: Route, from: Route, next: NavigationGuardNext) => {
  if (to.query.productId && to.query.priceId && to.query.freePlan) {
    next();
  } else {
    next("/select-plan");
  }
};

const isHasAllRequiredParamsForBillingInfo = (route: Route): boolean => {
  return !!(
    route.query.firstName &&
    route.query.lastName &&
    route.query.email &&
    route.query.companyName &&
    route.query.phoneNumber &&
    route.query.timezoneId &&
    route.query.productId &&
    route.query.priceId &&
    route.query.freePlan
  );
};

const expireGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
  if (!store.getters["currentBusinessAccount"]?.isExpired) {
    return next();
  } else {
    return next("/settings/billing?tab=change-plan");
  }
};

const routes: Array<RouteConfig> = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
  },
  {
    path: "/inbox",
    name: "Inbox",
    beforeEnter: expireGuard,
    meta: { title: "Shared Inbox", protected: true },
    component: () => import(/* webpackChunkName: "inbox" */ "../views/Inbox.vue")
  },
  {
    path: "/inbox/:id",
    name: "Inbox selected",
    beforeEnter: expireGuard,
    meta: { hideHeaderOnMobile: true, protected: true },
    component: () => import(/* webpackChunkName: "inbox" */ "../views/Inbox.vue")
  },
  {
    path: "/contacts",
    name: "Contacts",
    beforeEnter: expireGuard,
    meta: { whiteBackground: true, protected: true, customMobileHeader: true },
    component: () => import(/* webpackChunkName: "contacts" */ "../views/Contacts.vue")
  },
  {
    path: "/reports",
    name: "Reports",
    beforeEnter: expireGuard,
    meta: { whiteBackground: true, protected: true, customMobileHeader: true },
    component: () => import(/* webpackChunkName: "contacts" */ "../views/Reports.vue")
  },
  {
    path: "/super-admins",
    name: "Super Admin",
    beforeEnter: expireGuard,
    meta: { title: "Super admin", protected: true },
    component: () => import(/* webpackChunkName: "SuperAdmin" */ "../views/SuperAdmin.vue"),
    children: [
      {
        path: "companies",
        name: "Company",
        beforeEnter: expireGuard,
        meta: { title: "Company", backUrl: "/super-admins", protected: true },
        component: () =>
          import(/* webpackChunkName: "SuperAdmin" */ "../views/super-admins/Companies.vue")
      },
      {
        path: "companies/:id",
        name: "Company detail",
        beforeEnter: expireGuard,
        meta: {
          title: "Company detail",
          backUrl: "/super-admins",
          protected: true,
          customMobileHeader: true
        },
        component: () =>
          import(/* webpackChunkName: "SuperAdmin" */ "../views/settings/AutomationSteps.vue")
      }
    ]
  },
  {
    path: "/verify-token",
    name: "Verify Token",
    meta: { hideHeaderOnDesktop: true, hideHeaderOnMobile: true },
    component: () => import(/* webpackChunkName: "contacts" */ "../views/auth/VerifyToken.vue")
  },
  {
    path: "/settings",
    name: "Settings",
    beforeEnter: adminGuard,
    meta: { title: "Admin Settings", protected: true },
    component: () => import(/* webpackChunkName: "AdminSettings" */ "../views/AdminSettings.vue"),
    children: [
      {
        path: "widgets",
        name: "Widget Settings",
        beforeEnter: expireGuard,
        meta: { title: "URL Widgets", backUrl: "/settings", protected: true },
        component: () =>
          import(/* webpackChunkName: "AdminSettings" */ "../views/settings/WidgetSettings.vue")
      },
      {
        path: "company",
        name: "Company Settings",
        beforeEnter: expireGuard,
        meta: { title: "Company Settings", backUrl: "/settings", protected: true },
        component: () =>
          import(/* webpackChunkName: "AdminSettings" */ "../views/settings/CompanySettings.vue")
      },
      {
        path: "automations",
        name: "Automations",
        beforeEnter: expireGuard,
        meta: { title: "Automations", backUrl: "/settings", protected: true },
        component: () =>
          import(/* webpackChunkName: "AdminSettings" */ "../views/settings/Automations.vue")
      },
      {
        path: "automations/:automationId",
        name: "Automation steps",
        beforeEnter: expireGuard,
        meta: {
          title: "Automation steps",
          backUrl: "/settings",
          protected: true,
          customMobileHeader: true
        },
        component: () =>
          import(/* webpackChunkName: "AdminSettings" */ "../views/settings/AutomationSteps.vue")
      },
      {
        path: "custom-fields",
        name: "Custom fields",
        beforeEnter: expireGuard,
        meta: { title: "Custom fields", backUrl: "/settings", protected: true },
        component: () =>
          import(
            /* webpackChunkName: "AdminSettings" */ "../views/settings/CustomFieldsSettings.vue"
          )
      },
      {
        path: "textodeals-textobaines",
        name: "Textodeals/Textobaines",
        beforeEnter: expireGuard,
        meta: { title: "Textodeals/Textobaines title", backUrl: "/settings", protected: true },
        component: () =>
          import(/* webpackChunkName: "AdminSettings" */ "../views/settings/TextodealsSettings.vue")
      },
      {
        path: "saved-replies",
        name: "Reply Settings",
        beforeEnter: expireGuard,
        meta: { title: "Saved Replies", backUrl: "/settings", protected: true },
        component: () =>
          import(
            /* webpackChunkName: "AdminSettings" */ "../views/settings/SavedRepliesSettings.vue"
          )
      },
      {
        path: "organization",
        name: "My Organization",
        beforeEnter: expireGuard,
        meta: { title: "My Organization", backUrl: "/settings", protected: true },
        component: () =>
          import(
            /* webpackChunkName: "AdminSettings" */ "../views/settings/OrganizationSettings.vue"
          )
      },
      {
        path: "billing",
        name: "Billing Settings",
        meta: {
          title: "Billing Information",
          backUrl: "/settings",
          whiteBackground: true,
          protected: true
        },
        component: () =>
          import(/* webpackChunkName: "AdminSettings" */ "../views/settings/BillingSettings.vue")
      },
      {
        path: "integrations",
        name: "Integration Settings",
        meta: {
          title: "Integration Information",
          backUrl: "/settings",
          whiteBackground: false,
          protected: true
        },
        beforeEnter: expireGuard,
        component: () =>
          import(
            /* webpackChunkName: "AdminSettings" */ "../views/settings/IntegrationsSettings.vue"
          )
      }
    ]
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    beforeEnter: signUpGuard,
    meta: {
      whiteBackground: true,
      hideHeaderOnDesktop: true,
      backUrl: "/sign-in",
      title: "Create account"
    },
    component: () => import(/* webpackChunkName: "auth" */ "../views/auth/SignUp.vue")
  },
  {
    path: "/billing-info",
    name: "billing-info",
    beforeEnter: billingInfoGuard,
    meta: { whiteBackground: true, hideHeaderOnDesktop: true, title: "Billing Info" },
    component: () => import(/* webpackChunkName: "auth" */ "../views/auth/BillingInfo.vue")
  },
  {
    path: "/sign-in",
    name: "Sign In",
    meta: { whiteBackground: true, hideHeaderOnDesktop: true, title: "Sign in" },
    component: () => import(/* webpackChunkName: "auth" */ "../views/auth/SignIn.vue")
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    meta: {
      whiteBackground: true,
      hideHeaderOnDesktop: true,
      backUrl: "/",
      title: "Password recovery"
    },
    component: () => import(/* webpackChunkName: "auth" */ "../views/auth/PasswordRecovery.vue")
  },
  {
    path: "/change-password",
    name: "Change Password",
    meta: {
      whiteBackground: true,
      hideHeaderOnDesktop: true,
      backUrl: "/",
      title: "Change password"
    },
    component: () => import(/* webpackChunkName: "auth" */ "../views/auth/ChangePassword.vue")
  },
  {
    path: "/password-updated",
    name: "Password updated",
    meta: {
      whiteBackground: true,
      hideHeaderOnDesktop: true,
      backUrl: "/",
      title: "Password updated"
    },
    component: () => import(/* webpackChunkName: "auth" */ "../views/auth/PasswordUpdated.vue")
  },
  {
    path: "/confirm-plan",
    name: "Confirm Plan",
    meta: { whiteBackground: true, hideHeaderOnDesktop: true, backUrl: "/", title: "Confirm Plan" },
    component: () => import(/* webpackChunkName: "auth" */ "../views/auth/ConfirmPlan.vue")
  },
  {
    path: "/thanks",
    name: "Thanks for payment",
    meta: {
      whiteBackground: true,
      hideHeaderOnDesktop: true,
      backUrl: "/",
      title: "Thanks for Payment"
    },
    component: () => import(/* webpackChunkName: "auth" */ "../views/auth/ThanksForPayment.vue")
  },
  {
    path: "/plan-expire",
    name: "Plan expired",
    meta: { whiteBackground: true, hideHeaderOnDesktop: true, backUrl: "/", title: "Plan expired" },
    component: () => import(/* webpackChunkName: "auth" */ "../views/auth/PlanExpire.vue")
  },
  {
    path: "/select-plan",
    name: "Select Plan",
    meta: {
      whiteBackground: true,
      hideHeaderOnDesktop: true,
      backUrl: "/",
      title: "Select a number",
      withoutHeader: true
    },
    component: () => import(/* webpackChunkName: "auth" */ "../views/auth/SelectPlan.vue")
  },
  {
    path: "*",
    redirect: "/inbox"
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

const authGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
  if (store.getters["token"]) {
    return next();
  } else {
    return next("sign-in");
  }
};

router.beforeEach(async (to, from, next) => {
  const routeMeta = to.meta as RouteMeta;

  if (routeMeta.protected && !store.getters["token"]) {
    authGuard(to, from, next);
  } else if (!routeMeta.protected && store.getters["token"]) {
    next("/inbox");
  }

  if (routeMeta.title) {
    await store.dispatch("header/updateHeaderTitle", routeMeta.title);
    await store.dispatch("header/updateBackUrl", routeMeta.backUrl);
  }
  next();
});

export default router;
