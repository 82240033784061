import API from "@/http/http-client";
import ITag from "@/interfaces/models/tag.interface";
import { ICreateTag } from '@/interfaces/requests/ICreateTag';
import { IUpdateTag } from '@/interfaces/requests/IUpdateTag';

export const TagService = {
  async fetchTags(): Promise<ITag[]> {
    const {data} = await API.get('/dog/tags');
    return data;
  },

  async createTag(payload: ICreateTag): Promise<ITag> {
    const {data} = await API.post('/dog/tags', payload);
    return data;
  },

  async updateTag(payload: IUpdateTag): Promise<ITag> {
    const {data} = await API.patch(`/dog/tags/${payload.id}`, payload);
    return data;
  },

  async removeTag(TagId: number): Promise<void> {
    await API.delete(`/dog/tags/${TagId}`);
  },
}
