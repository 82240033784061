import {IStripeProduct} from "@/interfaces/models/IStripeProduct";
import API from "@/http/http-client";
import {IStripePrice} from "@/interfaces/models/IStripePrice";
import {ITaxCalculation} from "@/interfaces/models/ITaxCalculation";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import {ICreatePayment} from "@/interfaces/requests/ICreatePayment";
import axios from "axios";
import {IPromoCodeValidation} from "@/interfaces/models/IPromoCodeValidation";

export const ProductService = {
  async fetchProducts(): Promise<IStripeProduct[]> {
    const {data} = await API.get('/dog/stripe/products');
    return data;
  },

  async fetchProduct(productId: string): Promise<IStripeProduct> {

    const {data} = await API.get(`/dog/stripe/products/${productId}`);
    return data;
  },

  async fetchPrices(productId: string | null): Promise<IStripePrice[]> {
    const {data} = await API.get('/dog/stripe/prices', {
      params: {productId}
    });
    return data;
  },

  async fetchTaxes(provinceId: number, countryId: number, priceId: string): Promise<ITaxCalculation> {
    const {data} = await API.get('/dog/stripe/tax-calculation', {
      params: {countryId, priceId, provinceId}
    });
    return data;
  },

  async processPayment(paymentData: ICreatePayment): Promise<IBusinessAccount> {
    const {data} = await API.post('/dog/stripe/payments', paymentData);
    return data;
  },

  async updateSubscription(businessAccountId: number, priceId: string): Promise<IBusinessAccount> {
    const {data} = await API.patch(`/dog/business-accounts/${businessAccountId}/update-subscription`, {priceId});
    return data;
  },

  async validateCardNumber(cardNumber: number): Promise<{cardType: string}> {
    const {data} = await API.get('/validate-credit-card-number', {
      params: {cardNumber}
    });
    return data;
  },

  validatePromoCode(promoCode: string): IPromoCodeValidation {
    const cancelToken = axios.CancelToken.source();
    const response = API.post<{isValid: boolean}>(`/dog/promo-codes/validate`, {promoCode}, {
      cancelToken: cancelToken.token
    });
    return {cancelToken, response};
  }
}
