import {RootState, WidgetState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import WidgetItemInterface from "@/interfaces/widget-item.interface";
import {WidgetService} from "@/http/services/widget.service";

export const state: WidgetState = {
  widgets: []
};

export const getters: GetterTree<WidgetState, RootState> = {
  getWidgets: (state: WidgetState) => state.widgets
};

export const mutations: MutationTree<WidgetState> = {
  setWidgets: (state: WidgetState, widgets: WidgetItemInterface[]) => {
    state.widgets = widgets;
  },
  removeWidget: (state: WidgetState, widgetId: string) => {
    state.widgets = state.widgets.filter((widget: WidgetItemInterface) => widget.widgetId !== widgetId);
  }
};

export const actions: ActionTree<WidgetState, RootState> = {
  fetchWidgets: async ({commit}: ActionContext<WidgetState, RootState>) => {
    const widgets = await WidgetService.fetchWidgets();
    commit('setWidgets', widgets);
  },
  removeWidget: async ({commit}: ActionContext<WidgetState, RootState>, widgetId: string) => {
    commit('removeWidget', widgetId)
  }
}

export const widgets: Module<WidgetState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
