import {NotificationState, RootState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import * as mutationTypes from "@/store/mutation-types";
import * as actionTypes from "@/store/action-types";
import {INotification, NotificationType} from "@/interfaces/models/INotification";

export const state: NotificationState = {
  notifications: [],
};

export const getters: GetterTree<NotificationState, RootState> = {
  notifications: (state: NotificationState) => state.notifications,
};

export const mutations: MutationTree<NotificationState> = {
  [mutationTypes.ADD_NOTIFICATION]: (state: NotificationState, notification: INotification) => {
    state.notifications = [notification, ...state.notifications]
  },

  [mutationTypes.REMOVE_NOTIFICATION]: (state: NotificationState, notification: INotification) => {
    state.notifications = state.notifications.filter(n => n !== notification);
  },
};

export const actions: ActionTree<NotificationState, RootState> = {
  [actionTypes.SHOW_NOTIFICATION]: ({commit}: ActionContext<NotificationState, RootState>, notification: INotification) => {
    const randomId = Math.floor(Math.random() * 1000);
    commit(mutationTypes.ADD_NOTIFICATION, {...notification, id: randomId});
  },

  [actionTypes.CLOSE_NOTIFICATION]: ({commit}: ActionContext<NotificationState, RootState>, notification: INotification) => {
    commit(mutationTypes.REMOVE_NOTIFICATION, notification);
  },
};

export const notification: Module<NotificationState, RootState> = {
  state,
  getters,
  mutations,
  actions
};
