import {RootState, TagsState} from "@/interfaces/states";
import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import ITag from "@/interfaces/models/tag.interface";
import {TagService} from "@/http/services/tag.service";
import { ICreateTag } from '@/interfaces/requests/ICreateTag';
import { IUpdateTag } from '@/interfaces/requests/IUpdateTag';

export const state: TagsState = {
  tags: []
};

export const getters: GetterTree<TagsState, RootState> = {
  tags: (state: TagsState) => state.tags,
};

export const mutations: MutationTree<TagsState> = {
  [mutationTypes.SET_TAGS]: (state: TagsState, tags: ITag[]) => {
    state.tags = tags;
  }
};

export const actions: ActionTree<TagsState, RootState> = {
  [actionTypes.FETCH_TAGS]: async ({commit}: ActionContext<TagsState, RootState>) => {
    const tags = await TagService.fetchTags();
    commit(mutationTypes.SET_TAGS, tags);
  },

  [actionTypes.CREATE_TAG]: async ({dispatch}: ActionContext<TagsState, RootState>, payload: ICreateTag) => {
    await TagService.createTag(payload);
    await dispatch(actionTypes.FETCH_TAGS);
  },

  [actionTypes.UPDATE_TAG]: async ({dispatch}: ActionContext<TagsState, RootState>, payload: IUpdateTag) => {
    await TagService.updateTag(payload);
    await dispatch(actionTypes.FETCH_TAGS);
  },

  [actionTypes.REMOVE_TAG]: async ({dispatch}: ActionContext<TagsState, RootState>, tagId: number) => {
    await TagService.removeTag(tagId);
    await dispatch(actionTypes.FETCH_TAGS);
  }
};

export const tags: Module<TagsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
