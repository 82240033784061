










import {Vue, Component} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import IAgentAccount from "@/interfaces/models/agent-account.interface";

@Component
export default class NotificationSettings extends Vue {
  @Getter('agent') agent!: IAgentAccount;
  isShowNotifications: boolean = false;

  mounted(): void {
    this.isShowNotifications = this.agent.activeNotification;
  }

  saveSettings(): boolean {
    return this.isShowNotifications;
  }
}
