import { ConversationState, RootState } from "@/interfaces/states";
import { ActionContext, ActionTree, GetterTree, MutationTree } from "vuex";
import * as mutationTypes from "@/store/mutation-types";
import * as actionTypes from "@/store/action-types";
import IConversation from "@/interfaces/models/conversation.interface";
import { ConversationService } from "@/http/services/conversation.service";
import IContact from "@/interfaces/models/contact.interface";
import IMessage from "@/interfaces/models/message.interface";
import { NotificationType } from "@/interfaces/models/INotification";

export const state: ConversationState = {
  openedConversations: [],
  closedConversations: [],
  currentConversation: null
};

export const getters: GetterTree<ConversationState, RootState> = {
  openedConversations: (state: ConversationState) => state.openedConversations,
  closedConversations: (state: ConversationState) => state.closedConversations,
  currentConversation: (state: ConversationState) => state.currentConversation
};

export const mutations: MutationTree<ConversationState> = {
  [mutationTypes.SET_OPENED_CONVERSATIONS]: (
    state: ConversationState,
    conversations: IConversation[]
  ) => {
    state.openedConversations = conversations;
  },
  [mutationTypes.SET_MORE_OPENED_CONVERSATIONS]: (
    state: ConversationState,
    conversations: IConversation[]
  ) => {
    state.openedConversations = state.openedConversations.concat(conversations);
  },
  [mutationTypes.SET_CLOSED_CONVERSATIONS]: (
    state: ConversationState,
    conversations: IConversation[]
  ) => {
    state.closedConversations = conversations;
  },
  [mutationTypes.SET_MORE_CLOSED_CONVERSATIONS]: (
    state: ConversationState,
    conversations: IConversation[]
  ) => {
    state.closedConversations = state.closedConversations.concat(conversations);
  },
  [mutationTypes.SET_CURRENT_CONVERSATION]: (
    state: ConversationState,
    conversation: IConversation | null
  ) => {
    state.currentConversation = conversation;
  },
  [mutationTypes.UPDATE_LATEST_MESSAGE]: (state: ConversationState, message: IMessage) => {
    state.openedConversations = state.openedConversations.map((conversation: IConversation) =>
      conversation.id === message.conversationId
        ? {
            ...conversation,
            latestMessage: message
          }
        : conversation
    );
  }
};

export const actions: ActionTree<ConversationState, RootState> = {
  [actionTypes.FETCH_CONVERSATIONS]: async (
    { commit }: ActionContext<ConversationState, RootState>,
    payload: any
  ) => {
    const conversations = await ConversationService.fetchAllConversations(payload);
    const mutation = payload.isOpen
      ? mutationTypes.SET_OPENED_CONVERSATIONS
      : mutationTypes.SET_CLOSED_CONVERSATIONS;
    commit(mutation, conversations);
  },

  [actionTypes.FETCH_MORE_CONVERSATIONS]: async (
    { commit }: ActionContext<ConversationState, RootState>,
    payload: any
  ) => {
    const conversations = await ConversationService.fetchAllConversations(payload);
    const mutation = payload.isOpen
      ? mutationTypes.SET_MORE_OPENED_CONVERSATIONS
      : mutationTypes.SET_MORE_CLOSED_CONVERSATIONS;
    commit(mutation, conversations);
  },

  [actionTypes.FETCH_CURRENT_CONVERSATION]: async (
    { commit }: ActionContext<ConversationState, RootState>,
    conversationId: number
  ) => {
    const conversation = await ConversationService.fetchConversationById(conversationId);
    commit(mutationTypes.SET_CURRENT_CONVERSATION, conversation);
  },

  [actionTypes.CREATE_CONVERSATION]: async (
    { commit, dispatch }: ActionContext<ConversationState, RootState>,
    payload: any
  ) => {
    const conversation = await ConversationService.createConversation(payload);
    commit(mutationTypes.SET_CURRENT_CONVERSATION, conversation);
    // await dispatch(actionTypes.FETCH_CONVERSATIONS, true); // we'll do it after CREATE_CONVERSATION right away
    // await dispatch(actionTypes.FETCH_CONVERSATIONS, false)
  },

  [actionTypes.CREATE_CONVERSATION_INDIVIDUAL]: async (
    { commit, dispatch }: ActionContext<ConversationState, RootState>,
    payload: any
  ) => {
    const conversation = await ConversationService.createConversationIndividual(payload);
    commit(mutationTypes.SET_CURRENT_CONVERSATION, conversation);
    // await dispatch(actionTypes.FETCH_CONVERSATIONS, true);
    // await dispatch(actionTypes.FETCH_CONVERSATIONS, false)
  },

  [actionTypes.CHANGE_ASSIGNEE_AGENT]: async (
    { dispatch, commit, state, rootGetters }: ActionContext<ConversationState, RootState>,
    agentAccountId: number
  ) => {
    const updatedConversation = await ConversationService.updateConversation(
      state.currentConversation!.id,
      { agentAccountId }
    );

    commit(mutationTypes.SET_CURRENT_CONVERSATION, updatedConversation);
    await dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: true, currentPage: 1 });
    await dispatch(actionTypes.FETCH_MESSAGES, state.currentConversation!.id);
  },

  [actionTypes.CHANGE_ASSIGNEE_DEPARTMENT]: async (
    { dispatch, commit, state, rootGetters }: ActionContext<ConversationState, RootState>,
    departmentId: number
  ) => {
    const updatedConversation = await ConversationService.updateConversation(
      state.currentConversation!.id,
      { departmentId }
    );

    commit(mutationTypes.SET_CURRENT_CONVERSATION, updatedConversation);
    await dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: true, currentPage: 1 });
    await dispatch(actionTypes.FETCH_MESSAGES, state.currentConversation!.id);
  },

  [actionTypes.ADD_PARTICIPANT]: async (
    { state, commit }: ActionContext<ConversationState, RootState>,
    payload: object
  ) => {
    await ConversationService.addParticipant(state.currentConversation!.id, payload);
  },

  [actionTypes.REMOVE_PARTICIPANT]: async (
    { state, commit, rootGetters }: ActionContext<ConversationState, RootState>,
    contactId: number
  ) => {
    const currentParticipants: any = state
      .currentConversation!.contacts.map(({ id }: IContact) => id)
      .filter(id => id !== contactId);

    const updatedConversation = await ConversationService.updateConversation(
      state.currentConversation!.id,
      { contactIds: currentParticipants }
    );

    commit(mutationTypes.SET_CURRENT_CONVERSATION, updatedConversation);
  },

  [actionTypes.DEACTIVATE_CONTACT]: async (
    { commit, state, dispatch }: ActionContext<ConversationState, RootState>,
    contactId: number
  ) => {
    const updatedConversation = await ConversationService.deactivateContact(
      state.currentConversation!.id,
      contactId
    );
    commit(mutationTypes.SET_CURRENT_CONVERSATION, updatedConversation);
    await dispatch(actionTypes.FETCH_MESSAGES, state.currentConversation!.id);
    await dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: true, currentPage: 1 });
    await dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: false, currentPage: 1 });
  },

  [actionTypes.ACTIVATE_CONTACT]: async (
    { commit, state, dispatch }: ActionContext<ConversationState, RootState>,
    contactId: number
  ) => {
    const updatedConversation = await ConversationService.activateContact(
      state.currentConversation!.id,
      contactId
    );
    commit(mutationTypes.SET_CURRENT_CONVERSATION, updatedConversation);
    await dispatch(actionTypes.FETCH_MESSAGES, state.currentConversation!.id);
    await dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: true, currentPage: 1 });
    await dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: false, currentPage: 1 });
  },

  [actionTypes.TOGGLE_CONVERSATION]: async (
    { dispatch, state, commit }: ActionContext<ConversationState, RootState>,
    payload
  ) => {
    // to avoid updating conversation list twice - 1) when we call TOGGLE_CONVERSATION; 2) when the "conversationStatusUpdated" pusher event is triggered
    if (
      state[payload.isOpen ? "openedConversations" : "closedConversations"].some(
        c => c.id === payload.conversationId
      )
    ) {
      return;
    }

    const updatedConversation = await ConversationService.updateConversation(
      payload.conversationId,
      { isOpen: payload.isOpen }
    );

    if (payload.conversationId === state.currentConversation?.id) {
      commit(mutationTypes.SET_CURRENT_CONVERSATION, payload.isOpen ? updatedConversation : null);
      if (payload.isOpen) {
        dispatch(actionTypes.FETCH_MESSAGES, payload.conversationId);
      }
    }

    if (payload.isOpen) {
      const filteredClosedConversations = state.closedConversations.filter(
        ({ id }: IConversation) => id !== updatedConversation.id
      );

      commit(mutationTypes.SET_OPENED_CONVERSATIONS, [
        updatedConversation,
        ...state.openedConversations
      ]);
      commit(mutationTypes.SET_CLOSED_CONVERSATIONS, filteredClosedConversations);
    } else {
      const filteredOpenedConversations = state.openedConversations.filter(
        ({ id }: IConversation) => id !== updatedConversation.id
      );

      commit(mutationTypes.SET_CLOSED_CONVERSATIONS, [
        updatedConversation,
        ...state.closedConversations
      ]);
      commit(mutationTypes.SET_OPENED_CONVERSATIONS, filteredOpenedConversations);
    }
  },

  [actionTypes.UPDATE_CONVERSATION_NAME]: async (
    { dispatch, state, commit, rootGetters }: ActionContext<ConversationState, RootState>,
    { conversation, name }: { conversation: IConversation; name: string }
  ) => {
    const updatedConversation = await ConversationService.updateConversation(conversation.id, {
      name
    });

    if (updatedConversation?.id === state.currentConversation?.id) {
      commit(mutationTypes.SET_CURRENT_CONVERSATION, updatedConversation);
    }

    if (updatedConversation.isOpen) {
      const updateConversationList = state.openedConversations.map((conversation: IConversation) =>
        conversation.id === updatedConversation.id ? updatedConversation : conversation
      );
      commit(mutationTypes.SET_OPENED_CONVERSATIONS, updateConversationList);
    } else {
      const updateConversationList = state.closedConversations.map((conversation: IConversation) =>
        conversation.id === updatedConversation.id ? updatedConversation : conversation
      );
      commit(mutationTypes.SET_CLOSED_CONVERSATIONS, updateConversationList);
    }
  },

  [actionTypes.UPDATE_CONVERSATION_LATEST_MESSAGE]: (
    { commit, state, dispatch }: ActionContext<ConversationState, RootState>,
    message: IMessage
  ) => {
    commit(mutationTypes.UPDATE_LATEST_MESSAGE, message);

    const closedConversation = state.closedConversations.find(
      ({ id }: IConversation) => id === message.conversationId
    );

    if (closedConversation) {
      dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: true, currentPage: 1 });
      dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: false, currentPage: 1 });
    }

    dispatch(actionTypes.MESSAGE_RECEIVED, message);
  },

  [actionTypes.CLEAR_CURRENT_CONVERSATION]: ({
    commit
  }: ActionContext<ConversationState, RootState>) => {
    commit(mutationTypes.SET_CURRENT_CONVERSATION, null);
    commit(mutationTypes.SET_MESSAGES, []);
  },

  [actionTypes.REMOVE_CONVERSATION_AUTOMATION]: async (
    { commit, state, dispatch }: ActionContext<ConversationState, RootState>,
    automationId: number
  ) => {
    await ConversationService.removeAutomation(state.currentConversation!.id, automationId)
      .then(() => {
        dispatch(actionTypes.SHOW_NOTIFICATION, {
          text: "Automation has been successfully deleted",
          type: NotificationType.SUCCESS
        });
        dispatch(actionTypes.FETCH_CURRENT_CONVERSATION, state.currentConversation!.id);
      })
      .catch(error => {
        dispatch(actionTypes.SHOW_NOTIFICATION, {
          text: error.message,
          type: NotificationType.ERROR
        });
      });
  }
};

export const conversation = {
  state,
  getters,
  mutations,
  actions
};
