import API from "@/http/http-client";
import {ICreateBusinessAccount} from "@/interfaces/requests/ICreateBusinessAccount";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import {IAddress} from "@/interfaces/models/location.interface";
import {businessAccount} from "@/store/modules/business-account";

export const BusinessAccountService = {
  async fetchBusinessAccounts(): Promise<IBusinessAccount[]> {
    const {data} = await API.get(`/dog/business-accounts`);
    return data;
  },
  async fetchBusinessAccount(accountId: number): Promise<IBusinessAccount> {
    const {data} = await API.get(`/dog/business-accounts/${accountId}`);
    return data;
  },

  async updateBusinessAccount(payload: Partial<IBusinessAccount>, businessAccountId: number): Promise<IBusinessAccount> {
    const {data} = await API.patch(`/dog/business-accounts/${businessAccountId}`, payload);
    return data;
  },

  async enableBusinessAccount(businessAccountId: number): Promise<IBusinessAccount> {
    const {data} = await API.patch(`/dog/business-accounts/${businessAccountId}/enable`);
    return data;
  },

  async disableBusinessAccount(businessAccountId: number): Promise<IBusinessAccount> {
    const {data} = await API.patch(`/dog/business-accounts/${businessAccountId}/disable`);
    return data;
  },

  async updateBusinessAccountAddress(businessAccountId: number, address: IAddress): Promise<IBusinessAccount> {
    const {data} = await API.patch(`/dog/addresses/${businessAccountId}`, address);
    return data;
  },

  async createBusinessAccount(creationData: ICreateBusinessAccount): Promise<IBusinessAccount> {
    const {data} = await API.post(`/dog/business-accounts`, creationData);
    return data;
  },

  async checkEmailDuplicate(email: string): Promise<{ isDuplicate: boolean }> {
    const {data} = await API.get(`/dog/business-accounts/check-duplicate`, {
      params: {email},
    });
    return data;
  },

  async registrationAttempt(registrationData: object): Promise<IBusinessAccount> {
    const {data} = await API.post(`/dog/business-accounts/registration-attempt`, registrationData);
    return data;
  },
}
