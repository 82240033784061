























import {Component, Vue, Prop} from 'vue-property-decorator';
import IAgentAccount from "../../interfaces/models/agent-account.interface";
import IReport from "../../interfaces/models/report.interface";
import {Helpers} from "../utils/helpers.js";

@Component
export default class ReportAvatar extends Vue {
  @Prop({required: true}) report!: IReport;
  @Prop({type: String, default: '#4D7CFE'}) color!: string;
  @Prop({type: Number, default: 36}) size!: number;
  @Prop({type: Boolean, default: false}) botPhoto!: boolean;

  get profilePhoto(): string | boolean {
    if (this.botPhoto) {
      return '/img/default-photo.png';
    }

    return false;
  }

  get initials(): string {
    return Helpers.getInitialReport(this.report);
  }
}
