import {RootState, IdentifiersState} from "@/interfaces/states";
import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import IIdentifier from "@/interfaces/models/identifier.interface";
import {IdentifierService} from "@/http/services/identifier.service";

export const state: IdentifiersState = {
  identifiers: []
};

export const getters: GetterTree<IdentifiersState, RootState> = {
  identifiers: (state: IdentifiersState) => state.identifiers,
};

export const mutations: MutationTree<IdentifiersState> = {
  [mutationTypes.SET_IDENTIFIER]: (state: IdentifiersState, identifiers: IIdentifier[]) => {
    state.identifiers = identifiers;
  }
};

export const actions: ActionTree<IdentifiersState, RootState> = {
  [actionTypes.FETCH_IDENTIFIER]: async ({commit}: ActionContext<IdentifiersState, RootState>) => {
    const identifiers = await IdentifierService.fetchIdentifier();
    commit(mutationTypes.SET_IDENTIFIER, identifiers);
  }
};

export const identifiers: Module<IdentifiersState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
