import {BillingState, RootState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {BillingService} from "@/http/services/billing.service";
import * as mutationTypes from '@/store/mutation-types';
import * as actionTypes from '@/store/action-types';
import {IInvoice} from "@/interfaces/models/IInvoice";
import {IPaymentMethod} from "@/interfaces/models/IPaymentMethod";
import {IMessageCountHistory} from "@/interfaces/models/IMessageCountHistory";
import {IUpdatePaymentMethod} from "@/interfaces/requests/IUpdatePaymentMethod";

export const state: BillingState = {
  invoices: [],
  paymentMethods: [],
  messageCountHistory: [],
};

export const getters: GetterTree<BillingState, RootState> = {
  invoices: (state: BillingState) => state.invoices,
  paymentMethods: (state: BillingState) => state.paymentMethods,
  messageCountHistory: (state: BillingState) => state.messageCountHistory,
};

export const mutations: MutationTree<BillingState> = {
  [mutationTypes.SET_INVOICES]: (state: BillingState, invoices: IInvoice[]) => {
    state.invoices = invoices;
  },

  [mutationTypes.SET_PAYMENT_METHODS]: (state: BillingState, paymentsMethods: IPaymentMethod[]) => {
    state.paymentMethods = paymentsMethods;
  },

  [mutationTypes.SET_MESSAGE_COUNT_HISTORY]: (state: BillingState, messageCountHistory: IMessageCountHistory[]) => {
    state.messageCountHistory = messageCountHistory;
  },
};

export const actions: ActionTree<BillingState, RootState> = {
  [actionTypes.FETCH_INVOICES]: async ({commit, rootState}: ActionContext<BillingState, RootState>) => {
    const invoices = await BillingService.fetchInvoices(rootState.businessAccount.currentBusinessAccount!.id);
    commit(mutationTypes.SET_INVOICES, invoices);
  },

  [actionTypes.FETCH_PAYMENT_METHODS]: async ({commit, rootState}: ActionContext<BillingState, RootState>) => {
    const paymentMethods = await BillingService.fetchPaymentMethods(rootState.businessAccount.currentBusinessAccount!.id);
    commit(mutationTypes.SET_PAYMENT_METHODS, paymentMethods);
  },

  [actionTypes.UPDATE_PAYMENT_METHOD]: async ({dispatch, rootState}: ActionContext<BillingState, RootState>, updateData: IUpdatePaymentMethod) => {
    await BillingService.updatePaymentMethod(
      rootState.businessAccount.currentBusinessAccount!.id,
      updateData
    );
    await dispatch(actionTypes.FETCH_PAYMENT_METHODS);
  },

  [actionTypes.FETCH_MESSAGE_COUNT_HISTORY]: async ({commit, rootState}: ActionContext<BillingState, RootState>) => {
    const messageCountHistory = await BillingService.fetchMessageCountHistory(rootState.businessAccount.currentBusinessAccount!.id);
    commit(mutationTypes.SET_MESSAGE_COUNT_HISTORY, messageCountHistory);
  },
};

export const billing: Module<BillingState, RootState> = {
  state,
  getters,
  mutations,
  actions
};
