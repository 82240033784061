



















































































































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import IContact from "@/interfaces/models/contact.interface";
import { Validate } from "vuelidate-property-decorators";
import { minLength, required } from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import { Helpers } from "@/common/utils/helpers.js";
import { NotificationType } from "@/interfaces/models/INotification";
import ISavedReply from "@/interfaces/models/saved-reply.interface";
import SavedRepliesPopup from "@/components/inbox/SavedRepliesPopup.vue";
import { IDepartment } from "@/interfaces/models/IDepartment";
import imageLoadingHelper from "@/common/utils/image-loading-helper";
import GiphyPopup from "@/components/inbox/GiphyPopup.vue";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import { ContactsService } from "@/http/services/contacts.service";
import EmojiPicker from "../emoji-picker/EmojiPicker.vue";

@Component({
  components: { GiphyPopup, SavedRepliesPopup, EmojiPicker }
})
export default class ComposeMessagePopup extends Vue {
  @Prop({ type: Boolean, required: true }) showDialog!: boolean;
  @Prop({ type: Boolean, default: false }) isIndividual!: boolean;
  @Prop({ type: Array, default: null }) selected!: number[];
  @Prop({ type: String, default: null }) filteredParam!: string;
  @Prop({ type: Boolean, required: false, default: false }) isSelectAll!: boolean;

  // @Getter contacts!: IContact[];
  @Getter departments!: IDepartment[];
  @Getter currentBusinessAccount!: IBusinessAccount;
  @Getter contacts_ct!: number;
  @Getter allContacts!: IContact[];

  showEmojiPicker: boolean = false;
  search: string = "";
  isCreating: boolean = false;
  mediaFile: string = "";
  imageName: string = "";
  MAX_COUNT_OF_SELECTED_BEFORE_COMPOSE: number = 5;
  allowTransactionalMessage: boolean = false;
  promotionalMessage: boolean = false;
  loading: boolean = false;
  isLoadingContacts: boolean = false;
  selContactIds: number[] = [];
  sortedContacts: IContact[] = [];
  contacts_count: number = 0;

  @Validate({ minLength: minLength(1) })
  selectedContacts: Array<string | IContact> = [];

  @Validate({ required })
  selectedDepartment: number | object | null = null;

  @Validate({ required })
  message: string = "";

  messageIdentifier: string = "";

  @Emit("close-dialog")
  closeDialog(): void {
    this.mediaFile = "";
  }

  @Watch("selectedContacts")
  observeSelected(): void {
    this.search = "";
  }

  @Watch("search")
  async observeSearch(newSearchValue: any): Promise<void> {
    if (newSearchValue.includes("-")) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: "Do not use ' - ' symbol",
        type: NotificationType.WARNING
      });
      this.search = newSearchValue.replace("-", "");
    }
  }

  @Watch("contacts_ct")
  getWatchContactCt(): void {
    this.contacts_count = Number(this.contacts_ct);
  }

  @Watch("selected")
  observeSelectedContacts(): void {
    this.selContactIds = this.selected;
    this.selectedContacts = this.selected
      ? this.sortedContacts.filter((contact: IContact) => this.selected.includes(contact.id))
      : [];
  }

  @Watch("selContactIds")
  observeSelContactIds(): void {
    this.selectedContacts = this.sortedContacts.filter((contact: IContact) =>
      this.selContactIds.includes(contact.id)
    );
  }

  @Watch("showDialog")
  async showDialogChange() {
    if (!this.showDialog) {
      this.message = "";
      this.selectedContacts = [];
      this.showEmojiPicker = false;
      this.isCreating = false;
      this.selectedDepartment = null;
    } else {
      this.allowTransactionalMessage = this.currentBusinessAccount.allowTransactionalMessage;
      if (!this.departments.length) {
        await this.$store.dispatch(actionTypes.FETCH_DEPARTMENTS);
      }
      this.isLoadingContacts = true;
      this.isLoadingContacts = false;
      this.sortedContacts = this.allContacts;
      this.contacts_count = this.allContacts.length;
      this.selectedContacts = this.selected
        ? this.sortedContacts.filter((contact: IContact) => this.selected.includes(contact.id))
        : this.selectedContacts;
      this.selectedDepartment = this.departments[1].id;
    }

    if (this.showDialog) {
      document.addEventListener("keyup", this.handleCloseDialog);
    } else {
      document.removeEventListener("keyup", this.handleCloseDialog);
    }
  }

  showSwitchLabel(): string {
    if (this.promotionalMessage) {
      return "Only subscribers will receive this message";
    } else {
      return "Message must not contain any promotions";
    }
  }

  async findURL(): Promise<void> {
    this.isCreating = true;
    const res: any = await this.changeUrlFromText();
    this.isCreating = false;
    let replacedText = this.message;

    if (res) {
      res.convertedUrls.forEach((shortUrl: any, index: number) => {
        const url1 = res.matchUrls[index];
        replacedText = replacedText.replace(url1, shortUrl);
      });
      this.message = replacedText;
    }
  }

  async changeUrlFromText(): Promise<void> {
    // check url
    return await Helpers.urlify(this.message);
  }

  async updateMessage(): Promise<void> {
    this.findURL();
  }

  get selectedContactsNames(): string {
    return this.selectedContacts
      .map(c => (typeof c === "string" ? c : `${c.firstName} ${c.lastName}`))
      .join(", ");
  }

  getInitials(contact: IContact): string {
    return Helpers.getInitials(contact);
  }

  mounted(): void {}

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === "Escape") {
      this.closeDialog();
    }
  }

  loadImage(): void {
    (this.$refs.imageInput as HTMLInputElement).click();
  }

  async handleImageSelection(event: any): Promise<void> {
    try {
      this.imageName = event.target.files[0].name.split(".")[0];
      this.mediaFile = await imageLoadingHelper(event);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  async handleGIFSelection({ url, title }: { url: string; title: string }): Promise<void> {
    this.mediaFile = url.split("?cid")[0];
    this.imageName = title.split("GIF")[0].trim();
  }

  async searchContacts(filter_param: any): Promise<void> {
    this.isLoadingContacts = true;
    const res_contacts = await ContactsService.fetchContacts(filter_param);
    this.isLoadingContacts = false;
    this.sortedContacts = res_contacts.data;
    this.contacts_count = Number(res_contacts.total);
    // this.selectedContacts = this.selected ? this.sortedContacts.filter((contact: IContact) => this.selected.includes(contact.id)) : [];
  }

  async handleSearch(keyword: any): Promise<void> {
    if (keyword) {
      if (this.search && this.search.split(",").length > 1) {
        let tempItems = this.search.split(",");
        // let tempItems = this.search.split(/[, ]+/);
        for (let ele of tempItems) {
          if (ele !== "") {
            this.selectedContacts = this.selectedContacts.concat(ele);
          }
        }
        this.search = "";
      } else {
        // search contact by keyword
        let filter_param: any = {};
        filter_param["currentPage"] = 1;
        filter_param["perPage"] = 20;
        if (keyword.includes(" ")) {
          const keywords = keyword.split(" ");
          filter_param["firstName"] = keywords[0];
          filter_param["lastName"] = keywords[1];
        } else {
          filter_param["search"] = keyword;
        }
        await this.searchContacts(filter_param);
      }
    } else {
      this.search = "";
    }
  }

  handleEmojiSelect(emoji: string): void {
    const lastCursorPosition = document.querySelector("textarea")?.selectionStart || 0;
    this.message =
      this.message.substr(0, lastCursorPosition) + emoji + this.message.substr(lastCursorPosition);

    // outside click to close emoji picker
    document.body.click();
  }

  clearSelectedContacts(): void {
    this.selectedContacts = [];
  }

  getFormattedNumber(number: string): string {
    return Helpers.formatNumber(number);
  }

  get disableRecipients(): boolean {
    if (this.selected) {
      if (this.selected.length > 0 || this.isSelectAll) {
        return true;
      }
    }
    return false;
  }

  async sendMessage(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }
    this.isCreating = true;

    const res: any = await this.changeUrlFromText();
    let replacedText = this.message;
    if (res) {
      res.convertedUrls.forEach((shortUrl: any, index: number) => {
        const url1 = res.matchUrls[index];
        replacedText = this.message.replace(url1, shortUrl);
      });
      this.message = replacedText;
    }

    try {
      this.isCreating = true;

      const contactIds: number[] = [];
      const phoneNumbers: string[] = [];

      this.selectedContacts.forEach((selected: any) => {
        if (selected?.id) {
          contactIds.push(selected!.id);
        } else {
          phoneNumbers.push(selected);
        }
      });

      let filter_param: any = {
        contactIds: this.isSelectAll ? null : contactIds,
        selectedAll: this.isSelectAll,
        phoneNumbers,
        text: replacedText.trim(),
        media: this.mediaFile,
        departmentId: this.selectedDepartment,
        messageIdentifier: this.messageIdentifier,
        promotionalMessage: this.allowTransactionalMessage ? this.promotionalMessage : true
      };
      let filter_param_pass = this.filteredParam ? JSON.parse(this.filteredParam) : "";
      let params = { ...filter_param };
      const skipFields = ["contactIds", "selectedAll", "orderBy", "direction"];
      for (const key in filter_param_pass) {
        if (!skipFields.includes(key)) {
          filter_param_pass[key] = filter_param_pass[key].split(",");
        }
        if (key === "customFields") {
          filter_param_pass[key] = JSON.parse(filter_param_pass[key]);
        }
      }
      if (this.isSelectAll) {
        params = { ...filter_param, ...filter_param_pass };
      }

      await this.$store.dispatch(
        this.isIndividual
          ? actionTypes.CREATE_CONVERSATION_INDIVIDUAL
          : actionTypes.CREATE_CONVERSATION,
        params
      );

      this.closeDialog();
      this.message = this.mediaFile = "";
      this.isCreating = false;
      this.messageIdentifier = "";
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: "Good dog!",
        type: NotificationType.SUCCESS
      });

      await Promise.all([
        this.$store.dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: true, currentPage: 1 }),
        this.$route.params.id
          ? await this.$store.dispatch(actionTypes.FETCH_MESSAGES, +this.$route.params.id)
          : "",
        this.$store.dispatch(actionTypes.FETCH_CONVERSATIONS, { isOpen: false, currentPage: 1 }),
        this.$store.dispatch(actionTypes.FETCH_IDENTIFIER),
        this.$store.dispatch(actionTypes.FETCH_TAGS),
        this.$store.dispatch(actionTypes.FETCH_REPORTS)
      ]);

      this.$v.$reset();
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  handleReplySelection({ content }: ISavedReply): void {
    this.message += content;
  }

  contactsFilter(contact: IContact, value: string): boolean {
    if (value.includes(" ")) {
      const values = value.split(" ");
      return (
        contact.firstName?.toLocaleLowerCase().includes(values[0].toLocaleLowerCase()) ||
        contact.lastName?.toLocaleLowerCase().includes(values[1].toLocaleLowerCase())
      );
    } else {
      return (
        contact.firstName?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        contact.lastName?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        contact.phoneNumber.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
    }
  }
}
