import IConversation from "@/interfaces/models/conversation.interface";
import API from "@/http/http-client";

export const ConversationService = {
  async fetchAllConversations(filter: any = {}): Promise<IConversation[]> {
    const { data } = await API.get(`/dog/conversations`, {
      params: !filter["name"]
        ? {
            isOpen: String(filter["isOpen"]),
            currentPage: filter["currentPage"] || 1,
            perPage: 10
          }
        : {
            isOpen: String(filter["isOpen"]),
            name: filter["name"] || "",
            currentPage: filter["currentPage"] || 1,
            perPage: 10
          }
    });
    return data;
  },

  async fetchConversationById(conversationId: number): Promise<IConversation> {
    const { data } = await API.get(`/dog/conversations/${conversationId}`);
    return data;
  },

  async createConversation(payload: any): Promise<IConversation> {
    const { data } = await API.post(`/dog/conversations`, payload);
    return data;
  },

  async createConversationIndividual(payload: object): Promise<IConversation> {
    const { data } = await API.post(`/dog/conversations/individual`, payload);
    return data;
  },

  async updateConversation(conversationId: number, updateObject: object): Promise<IConversation> {
    const { data } = await API.patch(`/dog/conversations/${conversationId}`, { ...updateObject });
    return data;
  },

  async changeAssignee(agentAccountId: number, conversationId: number): Promise<IConversation> {
    const { data } = await API.patch(`/dog/conversations/${conversationId}`, {
      agentAccountId
    });
    return data;
  },

  async activateContact(conversationId: number, contactId: number): Promise<IConversation> {
    const { data } = await API.patch(
      `/dog/conversations/${conversationId}/activate-contact/${contactId}`
    );
    return data;
  },

  async deactivateContact(conversationId: number, contactId: number): Promise<IConversation> {
    const { data } = await API.patch(
      `/dog/conversations/${conversationId}/deactivate-contact/${contactId}`
    );
    return data;
  },

  async addParticipant(conversationId: number, payload: object): Promise<IConversation> {
    const { data } = await API.patch(`/dog/conversations/${conversationId}/add-contact`, payload);
    return data;
  },

  async removeAutomation(conversationId: number, automationId: number) {
    await API.patch(`/dog/conversations/${conversationId}/remove-automation/${automationId}`);
  }
};
