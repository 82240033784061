import AuthResponseInterface from "@/interfaces/responses/auth-response.interface";
import API from "@/http/http-client";

export const AuthService = {
  async login(email: string, password: string): Promise<AuthResponseInterface> {
    const {data} = await API.post('/dog/auth/login', {email, password});
    return data.token;
  },

  async login_as_admin(originalBusinessAccountId: number, targetBusinessAccountId: number): Promise<AuthResponseInterface> {
    const {data} = await API.post('/dog/auth/login-as-admin', {originalBusinessAccountId, targetBusinessAccountId});
    return data.token;
  },

  async logout_from_admin(): Promise<AuthResponseInterface> {
    const {data} = await API.post('/dog/auth/logout-from-admin');
    return data.token;
  }
};
