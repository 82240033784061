













































































import {Component, Vue} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import IAgentAccount, {AgentRole} from "@/interfaces/models/agent-account.interface";
import {Helpers} from "@/common/utils/helpers.js";
import * as actionTypes from "@/store/action-types";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import UserSettingsMobile from "@/components/user-settings/UserSettingsMobile.vue";
import store from '@/store';

@Component({
  components: {UserSettingsMobile}
})
export default class NavigationDrawer extends Vue {
  isShowDrawer: boolean = false;
  isShowUserSettings: boolean = false;
  @Getter agent!: IAgentAccount;
  @Getter currentBusinessAccount!: IBusinessAccount;

  showDrawer(): void {
    this.isShowDrawer = true;
  }

  get profilePhoto(): string | boolean {
    return Helpers.getProfilePhoto(this.agent);
  }

  get initials(): string | boolean {
    return Helpers.getInitials(this.agent);
  }

  get isAdmin(): boolean {
    return this.agent?.role === AgentRole.ADMINISTRATOR;
  }

  get businessAccountPhoneNumber(): string {
    return Helpers.formatNumber(this.currentBusinessAccount.messageProxyNumber);
  }

  async logout(): Promise<void> {
    await this.$store.dispatch(actionTypes.SIGN_OUT);
    this.$store.dispatch(actionTypes.RESET);
    await this.$router.push('/sign-in');
  }
}
