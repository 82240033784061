












import { Component, Prop, Vue } from "vue-property-decorator";
import { INotification, NotificationType } from "@/interfaces/models/INotification";
import * as actionTypes from "@/store/action-types";

@Component
export default class NotificationItem extends Vue {
  @Prop({ type: Object, required: true }) notification!: INotification;
  timeoutId: number = 0;

  mounted(): void {
    this.timeoutId = setTimeout(this.closeNotification, 5000);
  }

  closeNotification(): void {
    this.$store.dispatch(actionTypes.CLOSE_NOTIFICATION, this.notification);
  }

  isSuccess({ type }: INotification) {
    return type === NotificationType.SUCCESS;
  }

  isWarning({ type }: INotification) {
    return type === NotificationType.WARNING;
  }

  isError({ type }: INotification) {
    return type === NotificationType.ERROR;
  }

  beforeDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
