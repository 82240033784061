import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ENV } from "@/common/utils/env";
import router from "../router";
import * as actionTypes from "@/store/action-types";
import store from "@/store";

const API = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "/v1/" : ENV.BASE_URL
});

API.interceptors.request.use(onRequestSuccess, onRequestRejected);
API.interceptors.response.use(onResponseSuccess, onResponseRejected);

function onRequestSuccess(config: AxiosRequestConfig) {
  const token = localStorage.getItem("token");

  config.withCredentials = true;
  config.headers = {
    ...config.headers,
    "Content-Type":
      config.url === "/dog/contacts/import" ? "multipart/form-data" : "application/json"
  };

  if (token) {
    config.headers.Authorization = JSON.parse(token);
  }

  return config;
}

function onRequestRejected(error: AxiosError) {
  return Promise.reject(error.response?.data);
}

async function onResponseSuccess(response: AxiosResponse) {
  const res: any = response;
  const error = res.data?.error || null;
  if (error) {
    if (error === "Token has Expired. Please login again") {
      // auto logout
      store.dispatch(actionTypes.SIGN_OUT);
      await router.push("/sign-in");
    }
  }
  return response;
}

async function onResponseRejected({
  response
}: {
  response: AxiosResponse;
  config: AxiosRequestConfig;
}) {
  const res: any = response;
  const error = res.data?.error || null;
  if (error) {
    if (error === "Token is missing in the Header.") {
      // auto logout
      store.dispatch(actionTypes.SIGN_OUT);
      await router.push("/sign-in");
    }
  }
  return Promise.reject(response?.data || "");
}

export default API;
