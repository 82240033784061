import {AgentsState, RootState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import {AgentsService} from "@/http/services/agents.service";

export const state: AgentsState = {
  agents: []
};

export const getters: GetterTree<AgentsState, RootState> = {
  agents: (state: AgentsState) => state.agents
};

export const mutations: MutationTree<AgentsState> = {
  [mutationTypes.SET_AGENTS]: (state: AgentsState, agents: IAgentAccount[]) => {
    state.agents = agents;
  },

  [mutationTypes.UPDATE_AGENTS]: (state: AgentsState, updatedAgent: IAgentAccount) => {
    state.agents = state.agents
      .map((agent: IAgentAccount) => updatedAgent.id === agent.id ? updatedAgent : agent);
  },

  [mutationTypes.ADD_AGENT]: (state: AgentsState, agent: IAgentAccount) => {
    state.agents = state.agents.concat(agent);
  }
};

export const actions: ActionTree<AgentsState, RootState> = {
  [actionTypes.FETCH_AGENTS]: async ({commit, rootGetters}: ActionContext<AgentsState, RootState>) => {
    const agents = await AgentsService.fetchAgents(rootGetters.agent.businessAccount.id);
    commit(mutationTypes.SET_AGENTS, agents);
  },

  [actionTypes.UPDATE_AGENT]: async ({commit, dispatch}: ActionContext<AgentsState, RootState>, updateData: IAgentAccount) => {
    const updateAgent = await AgentsService.updateAgent(updateData.id, updateData);
    commit(mutationTypes.UPDATE_AGENTS, updateAgent);
  },

  [actionTypes.CREATE_AGENT]: async ({commit, rootGetters}: ActionContext<AgentsState, RootState>, createData: object) => {
    const updateAgent = await AgentsService.createAgent({
      ...createData,
      businessAccountId: rootGetters.agent.businessAccount.id,
    });
    commit(mutationTypes.ADD_AGENT, updateAgent);
  },

  [actionTypes.REMOVE_AGENT]: async ({dispatch}: ActionContext<AgentsState, RootState>, id: number) => {
    await AgentsService.removeAgent(id);
    await dispatch(actionTypes.FETCH_AGENTS);
  },

  [actionTypes.ACTIVATE_AGENT]: async ({dispatch}: ActionContext<AgentsState, RootState>, agentId: number) => {
    await AgentsService.activateAgent(agentId);
    await dispatch(actionTypes.FETCH_AGENTS);
  },

  [actionTypes.DEACTIVATE_AGENT]: async ({dispatch}: ActionContext<AgentsState, RootState>, agentId: number) => {
    await AgentsService.deactivateAgent(agentId);
    await dispatch(actionTypes.FETCH_AGENTS);
  },

  [actionTypes.RESEND_INVITATION]: async ({dispatch}: ActionContext<AgentsState, RootState>, agentId: number) => {
    await AgentsService.resendInvitation(agentId);
  },
};

export const agents: Module<AgentsState, RootState> = {
  state,
  getters,
  mutations,
  actions
};
