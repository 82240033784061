import API from '@/http/http-client';
import { ICustomField } from '@/interfaces/models/ICustomField';
import { ICreateCustomField } from '@/interfaces/requests/ICreateCustomField';
import { IUpdateCustomField } from '@/interfaces/requests/IUpdateCustomField';

export const CustomFieldService = {
  async fetchCustomFields(): Promise<ICustomField[]> {
    const {data} = await API.get('/dog/custom-fields');
    return data;
  },

  async createCustomField(payload: ICreateCustomField): Promise<ICustomField> {
    const {data} = await API.post('/dog/custom-fields', payload);
    return data;
  },

  async updateCustomField(payload: IUpdateCustomField): Promise<ICustomField> {
    const {data} = await API.patch(`/dog/custom-fields/${payload.id}`, payload);
    return data;
  },

  async removeCustomField(customFieldId: number): Promise<void> {
    await API.delete(`/dog/custom-fields/${customFieldId}`);
  },
};
