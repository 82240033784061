



































import { Component, Vue } from "vue-property-decorator";
import VueEmojiPicker from "vue-emoji-picker";

@Component({
  components: { VueEmojiPicker }
})
export default class EmojiPicker extends Vue {
  search: string = "";
  append(emoji: Event): void {
    this.$emit("emojiUnicodeAdded", emoji);
  }
}
