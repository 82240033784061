





























import {Component, Vue} from 'vue-property-decorator';
import Vuetify from "vuetify";
import {Getter} from "vuex-class";
import IConversation from "@/interfaces/models/conversation.interface";

@Component
export default class Notification extends Vue {
  @Getter openedConversations!: IConversation[];

  get isHasNotification(): boolean {
    return this.openedConversations.some(c => !!c.latestMessage?.contact);
  }
}
