import {IInvoice} from "@/interfaces/models/IInvoice";
import API from "@/http/http-client";
import {IPaymentMethod} from "@/interfaces/models/IPaymentMethod";
import {IMessageCountHistory} from "@/interfaces/models/IMessageCountHistory";
import {IUpdatePaymentMethod} from "@/interfaces/requests/IUpdatePaymentMethod";

export const BillingService = {
  async fetchInvoices(businessAccountId: number): Promise<IInvoice[]> {
    const {data} = await API.get(`/dog/stripe/invoices`, {
      params: {businessAccountId}
    });
    return data;
  },

  async fetchPaymentMethods(businessAccountId: number): Promise<IPaymentMethod[]> {
    const {data} = await API.get(`/dog/stripe/payment-methods`, {
      params: {businessAccountId}
    });
    return data;
  },

  async updatePaymentMethod(businessAccountId: number, updateData: IUpdatePaymentMethod): Promise<void> {
    await API.patch(`/dog/business-accounts/${businessAccountId}/update-payment-method`, updateData);
  },

  async fetchMessageCountHistory(businessAccountId: number): Promise<IMessageCountHistory[]> {
    const {data} = await API.get(`/dog/message-count-histories`, {
      params: {businessAccountId}
    });
    return data;
  },
}
