import * as mutationTypes from '@/store/mutation-types';
import * as actionTypes from '@/store/action-types';
import router from '@/router';
import { EndWebhooksState, RootState } from '@/interfaces/states';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IEndWebhook } from '@/interfaces/models/IEndWebhook';
import { EndWebhooksService } from '@/http/services/end-webhooks.service';
import { ICreateEndWebhook } from '@/interfaces/requests/ICreateEndWebhook';
import { IUpdateEndWebhook } from '@/interfaces/requests/IUpdateEndWebhook';

export const state: EndWebhooksState = {
  endWebhooks     : [],
  endWebhook      : null
};

export const getters: GetterTree<EndWebhooksState, RootState> = {
  endWebhooks     : (state: EndWebhooksState) => state.endWebhooks,
  endWebhook      : (state: EndWebhooksState) => state.endWebhook,
};

export const mutations: MutationTree<EndWebhooksState> = {
  [mutationTypes.SET_END_WEBHOOKS]: (state: EndWebhooksState, endWebhooks: IEndWebhook[]) => {
    state.endWebhooks = endWebhooks;
  },

  [mutationTypes.SET_END_WEBHOOK]: (state: EndWebhooksState, endWebhook: IEndWebhook) => {
    state.endWebhook = endWebhook;
  },
};

export const actions: ActionTree<EndWebhooksState, RootState> = {
  [actionTypes.FETCH_END_WEBHOOKS]: async ({commit}: ActionContext<EndWebhooksState, RootState>, automationId: number) => {
    const endWebhooks = await EndWebhooksService.fetchEndWebhooks(automationId);
    commit(mutationTypes.SET_END_WEBHOOKS, endWebhooks);
  },

  [actionTypes.FETCH_END_WEBHOOK_BY_ID]: async ({commit}: ActionContext<EndWebhooksState, RootState>, id: number) => {
    const endWebhook = await EndWebhooksService.fetchEndWebhook(id);
    commit(mutationTypes.SET_END_WEBHOOK, endWebhook);
  },

  [actionTypes.CREATE_END_WEBHOOK]: async ({dispatch}: ActionContext<EndWebhooksState, RootState>, payload: ICreateEndWebhook): Promise<IEndWebhook> => {
    const createdEndWebhook = await EndWebhooksService.createEndWebhook(payload);
    await dispatch(actionTypes.FETCH_END_WEBHOOKS, router.currentRoute.params.id);

    return createdEndWebhook;
  },

  [actionTypes.UPDATE_END_WEBHOOK]: async ({
                                            dispatch,
                                            state,
                                            commit
                                          }: ActionContext<EndWebhooksState, RootState>, payload: IUpdateEndWebhook) => {
    const updatedEndWebhook = await EndWebhooksService.updateEndWebhook(payload);
    await dispatch(actionTypes.FETCH_END_WEBHOOKS, router.currentRoute.params.id);

    if (state.endWebhook?.id === updatedEndWebhook.id) {
      commit(mutationTypes.SET_END_WEBHOOK, updatedEndWebhook);
    }
  },

  [actionTypes.REMOVE_END_WEBHOOK]: async ({dispatch}: ActionContext<EndWebhooksState, RootState>, id: number) => {
    await EndWebhooksService.removeEndWebhook(id);
    await dispatch(actionTypes.FETCH_END_WEBHOOKS, router.currentRoute.params.id);
  },
};

export const endWebhooks: Module<EndWebhooksState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
