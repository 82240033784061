








import {Component, Vue} from 'vue-property-decorator';

@Component
export default class TextodogLogo extends Vue {
  indexUrl: string = '';

  created(): void {
    this.indexUrl = location.href.includes('beta')
      ? 'https://textodog.com'
      : 'https://textodog.com/';
  }
}
