import imageCompression from 'browser-image-compression';

const MAX_IMAGE_SIZE_MB: number = 1;
const MAX_IMAGE_WIDTH: number = 1920;
const MAX_IMAGE_SIZE: number = 1_500_000;

async function compressImage(image: File): Promise<File> {
  return await imageCompression(image, {
    maxSizeMB: MAX_IMAGE_SIZE_MB,
    maxWidthOrHeight: MAX_IMAGE_WIDTH,
  });
}

async function processFileLoad(event: any): Promise<string> {
  const file = await extractFileFromEvent(event);
  const compressedImage = await compressImage(file);

  return await readFileAsync(compressedImage);
}

function extractFileFromEvent(event: any): Promise<File> {
  const files = event.target.files;

  if (!files.length) {
    return Promise.reject({message: 'File not received'});
  }

  if (!files[0].type.match('image.*')) {
    return Promise.reject({message: 'Select an image'});
  }

  return files[0];
}

function readFileAsync(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async function (e: any) {
      if (e.total > MAX_IMAGE_SIZE) {
        reject({message: 'Image size too big'})
      }

      resolve(e.target.result);
    }

    reader.readAsDataURL(file);
  })
}

export default processFileLoad;
