






























































































import {Vue, Component, Watch, Prop} from 'vue-property-decorator';
import {Validate} from "vuelidate-property-decorators";
import {required, email, minLength} from "vuelidate/lib/validators";
import {Getter} from "vuex-class";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import ImageDropArea from "@/common/reusable-components/ImageDropArea.vue";
import * as actionTypes from "@/store/action-types";
import {NotificationType} from "@/interfaces/models/INotification";
import {Helpers} from "@/common/utils/helpers.js";

@Component({
  components: {ImageDropArea}
})
export default class ProfileSettings extends Vue {

  @Getter('agent') agent!: IAgentAccount;
  firstNameErrorMessage: string = '';
  lastNameErrorMessage: string = '';
  emailErrorMessage: string = '';

  isInited: boolean = false;
  isLoading: boolean = false;

  photo: string = '';

  @Validate({required})
  firstName: string = '';

  @Validate({required})
  lastName: string = '';

  @Validate({required, email})
  email: string = '';

  @Validate({minLength: minLength(14)})
  phoneNumber: string = '';

  @Watch('firstName')
  observeFirstName(): void {
    if (!this.$v.firstName.$dirty) {
      return;
    }

    if (this.$v.firstName.$invalid) {
      this.firstNameErrorMessage = 'This field is required';
    } else {
      this.firstNameErrorMessage = '';
    }
  }

  @Watch('lastName')
  observeLastName(): void {
    if (!this.$v.lastName.$dirty) {
      return;
    }

    if (this.$v.lastName.$invalid) {
      this.lastNameErrorMessage = 'This field is required';
    } else {
      this.lastNameErrorMessage = '';
    }
  }

  @Watch('email')
  observeEmail(): void {
    if (!this.$v.email.$dirty) {
      return;
    }

    if (this.$v.email.$invalid) {
      this.emailErrorMessage = !this.$v.email.email ? 'Bad email address' : 'This field is required';
    } else {
      this.emailErrorMessage = '';
    }
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  mounted(): void {
    this.firstName = this.agent.firstName;
    this.lastName = this.agent.lastName;
    this.email = this.agent.email;
    this.phoneNumber = this.agent?.phoneNumber ? this.agent.phoneNumber.slice(2)
      .replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3') : '';
    this.isInited = true;
    this.photo = this.agent.photo || '';
  }

  isSomethingChanged(): boolean {
    return this.firstName !== this.agent.firstName
      || this.lastName !== this.agent.lastName
      || this.email !== this.agent.email
      || Helpers.clearPhoneNumberFormatting(this.phoneNumber) !== this.agent.phoneNumber;

  }

  saveSettings(): object | null {
    this.$v.$touch();

    // if (!this.isSomethingChanged()) {
    //   return false
    // }

    if (this.$v.$invalid) {
      return null;
    }

    this.observeFirstName();
    this.observeLastName();
    this.observeEmail();

    const updateData = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: Helpers.clearPhoneNumberFormatting(this.phoneNumber),
      photo: this.photo,
    };

    if (this.photo.startsWith('http')) {
      delete updateData.photo;
    }

    return updateData;
  }

  async updateProfile(): Promise<void> {
    try {
      const updateData = this.saveSettings();

      if (updateData) {
        this.isLoading = true;
        await this.$store.dispatch(actionTypes.UPDATE_CURRENT_AGENT, updateData);
        await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
          text: 'Good dog!',
          type: NotificationType.SUCCESS
        });
        this.$emit('changed');
      }
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }
}
