import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';
import {RootState, SavedRepliesState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import ISavedReply from "@/interfaces/models/saved-reply.interface";
import {SavedRepliesService} from "@/http/services/saved-replies.service";

export const state: SavedRepliesState = {
  replies: [],
};

export const getters: GetterTree<SavedRepliesState, RootState> = {
  savedReplies: (state: SavedRepliesState) => state.replies
    .sort((a, b) => a.name > b.name ? 1 : -1),
};

export const mutations: MutationTree<SavedRepliesState> = {
  [mutationTypes.SET_SAVED_REPLIES]: (state: SavedRepliesState, savedReplies: ISavedReply[]) => {
    state.replies = savedReplies;
  },

  [mutationTypes.APPEND_SAVED_REPLY]: (state: SavedRepliesState, savedReply: ISavedReply) => {
    state.replies = state.replies.concat(savedReply);
  },

  [mutationTypes.UPDATE_SAVED_REPLY]: (state: SavedRepliesState, savedReply: ISavedReply) => {
    state.replies = state.replies.map(r => r.id === savedReply.id ? savedReply : r);
  },

  [mutationTypes.REMOVE_SAVED_REPLY]: (state: SavedRepliesState, replyToRemove: number) => {
    state.replies = state.replies.filter(r => r.id !== replyToRemove);
  },
};

export const actions: ActionTree<SavedRepliesState, RootState> = {
  [actionTypes.FETCH_SAVED_REPLIES]: async ({commit}: ActionContext<SavedRepliesState, RootState>) => {
    const savedReplies = await SavedRepliesService.fetchSavedReplies();
    commit(mutationTypes.SET_SAVED_REPLIES, savedReplies);
  },

  [actionTypes.UPDATE_SAVED_REPLY]: async ({commit}: ActionContext<SavedRepliesState, RootState>, savedReply: ISavedReply) => {
    const updatedReply = await SavedRepliesService.updateSavedReply(savedReply);
    commit(mutationTypes.UPDATE_SAVED_REPLY, updatedReply);
  },

  [actionTypes.CREATE_SAVED_REPLY]: async ({commit}: ActionContext<SavedRepliesState, RootState>, savedReply: ISavedReply) => {
    const createdReply = await SavedRepliesService.createSavedReply(savedReply);
    commit(mutationTypes.APPEND_SAVED_REPLY, createdReply);
  },

  [actionTypes.REMOVE_SAVED_REPLY]: async ({commit}: ActionContext<SavedRepliesState, RootState>, replyId: number) => {
    await SavedRepliesService.removeSavedReply(replyId);
    commit(mutationTypes.REMOVE_SAVED_REPLY, replyId);
  },
};

export const reply: Module<SavedRepliesState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
