export const RESET = "RESET";
// Auth module
export const LOGIN = "LOGIN";
export const LOGIN_AS_ADMIN = "LOGIN_AS_ADMIN";
export const LOGOUT_FROM_ADMIN = "LOGOUT_FROM_ADMIN";
export const FETCH_CURRENT_AGENT = "FETCH_CURRENT_AGENT";
export const UPDATE_CURRENT_AGENT = "UPDATE_CURRENT_AGENT";
export const SIGN_OUT = "SIGN_OUT";
export const RESTORE_AUTH = "RESTORE_AUTH";

// Conversation module
export const FETCH_CONVERSATIONS = "FETCH_CONVERSATIONS";
export const FETCH_MORE_CONVERSATIONS = "FETCH_MORE_CONVERSATIONS";
export const FETCH_CURRENT_CONVERSATION = "FETCH_CURRENT_CONVERSATION";
export const CREATE_CONVERSATION = "CREATE_CONVERSATION";
export const CREATE_CONVERSATION_INDIVIDUAL = "CREATE_CONVERSATION_INDIVIDUAL";
export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION";
export const ADD_PARTICIPANT = "ADD_PARTICIPANT";
export const REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT";
export const TOGGLE_CONVERSATION = "TOGGLE_CONVERSATION";
export const UPDATE_CONVERSATION_NAME = "UPDATE_CONVERSATION_NAME";
export const UPDATE_CONVERSATION_LATEST_MESSAGE = "UPDATE_CONVERSATION_LATEST_MESSAGE";
export const CHANGE_ASSIGNEE_AGENT = "CHANGE_ASSIGNEE_AGENT";
export const CHANGE_ASSIGNEE_DEPARTMENT = "CHANGE_ASSIGNEE_DEPARTMENT";
export const DEACTIVATE_CONTACT = "DEACTIVATE_CONTACT";
export const ACTIVATE_CONTACT = "ACTIVATE_CONTACT";
export const CLEAR_CURRENT_CONVERSATION = "CLEAR_CURRENT_CONVERSATION";
export const REMOVE_CONVERSATION_AUTOMATION = "REMOVE_CONVERSATION_AUTOMATION";

// Messages module
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";

// Contacts module
export const FETCH_CONTACTS = "FETCH_CONTACTS";
export const FETCH_ALL_CONTACTS = "FETCH_ALL_CONTACTS";
export const FETCH_CONTACT_BY_ID = "FETCH_CONTACT_BY_ID";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const REMOVE_CONTACTS = "REMOVE_CONTACTS";
export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const REMOVE_TAG_FOR_MULTIPLE_CONTACTS = "REMOVE_TAG_FOR_MULTIPLE_CONTACTS";
export const ADD_TAG_FOR_MULTIPLE_CONTACTS = "ADD_TAG_FOR_MULTIPLE_CONTACTS";

// Reports module
export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORT_BY_ID = "FETCH_REPORT_BY_ID";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const CREATE_REPORT = "CREATE_REPORT";
export const REMOVE_REPORT = "REMOVE_REPORT";
export const REMOVE_TAG_FOR_MULTIPLE_REPORTS = "REMOVE_TAG_FOR_MULTIPLE_REPORTS";
export const ADD_TAG_FOR_MULTIPLE_REPORTS = "ADD_TAG_FOR_MULTIPLE_REPORTS";

// Tags module
export const FETCH_TAGS = "FETCH_TAGS";
export const CREATE_TAG = "CREATE_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const REMOVE_TAG = "REMOVE_TAG";

// Identifier module
export const FETCH_IDENTIFIER = "FETCH_IDENTIFIER";

// Drawer module
export const OPEN_DRAWER_TO_EDIT = "OPEN_DRAWER_TO_EDIT";
export const OPEN_DRAWER_TO_CREATE = "OPEN_DRAWER_TO_CREATE";
export const CLOSE_DRAWER = "CLOSE_DRAWER";

// Business account module
export const FETCH_BUSINESS_ACCOUNTS = "FETCH_BUSINESS_ACCOUNTS";
export const FETCH_BUSINESS_ACCOUNT_BY_ID = "FETCH_BUSINESS_ACCOUNT_BY_ID";
export const UPDATE_BUSINESS_ACCOUNT = "UPDATE_BUSINESS_ACCOUNT";
export const UPDATE_BUSINESS_ACCOUNT_ADDRESS = "UPDATE_BUSINESS_ACCOUNT_ADDRESS";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const ENABLE_BUSINESS_ACCOUNT = "ENABLE_BUSINESS_ACCOUNT";
export const DISABLE_BUSINESS_ACCOUNT = "DISABLE_BUSINESS_ACCOUNT";

// Snackbar module
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

// Agent module
export const FETCH_AGENTS = "FETCH_AGENTS";
export const FETCH_AGENT_BY_ID = "FETCH_AGENT_BY_ID";
export const UPDATE_AGENT = "UPDATE_AGENT";
export const CREATE_AGENT = "CREATE_AGENT";
export const REMOVE_AGENT = "REMOVE_AGENT";
export const ACTIVATE_AGENT = "ACTIVATE_AGENT";
export const DEACTIVATE_AGENT = "DEACTIVATE_AGENT";
export const RESEND_INVITATION = "RESEND_INVITATION";

// Billing module
export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const FETCH_MESSAGE_COUNT_HISTORY = "FETCH_MESSAGE_COUNT_HISTORY";

// Stripe product module
export const FETCH_STRIPE_PRODUCTS = "FETCH_STRIPE_PRODUCTS";
export const FETCH_STRIPE_PRICES = "FETCH_STRIPE_PRICES";

// Saved replies module
export const FETCH_SAVED_REPLIES = "FETCH_SAVED_REPLIES";
export const CREATE_SAVED_REPLY = "CREATE_SAVED_REPLY";
export const UPDATE_SAVED_REPLY = "UPDATE_SAVED_REPLY";
export const REMOVE_SAVED_REPLY = "REMOVE_SAVED_REPLY";

// Departments module
export const FETCH_DEPARTMENTS = "FETCH_DEPARTMENTS";
export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const REMOVE_DEPARTMENT = "REMOVE_DEPARTMENT";

// Automations module
export const FETCH_AUTOMATIONS = "FETCH_AUTOMATIONS";
export const FETCH_AUTOMATION_BY_ID = "FETCH_AUTOMATION_BY_ID";
export const CREATE_AUTOMATION = "CREATE_AUTOMATION";
export const UPDATE_AUTOMATION = "UPDATE_AUTOMATION";
export const ENABLE_AUTOMATION = "ENABLE_AUTOMATION";
export const DISABLE_AUTOMATION = "DISABLE_AUTOMATION";
export const REMOVE_AUTOMATION = "REMOVE_AUTOMATION";
export const RESET_ACTIVE_AUTOMATION = "RESET_ACTIVE_AUTOMATION";

// Automation steps module
export const FETCH_AUTOMATION_STEPS = "FETCH_AUTOMATION_STEPS";
export const FETCH_AUTOMATION_STEP = "FETCH_AUTOMATION_STEP";
export const CREATE_AUTOMATION_STEP = "CREATE_AUTOMATION_STEP";
export const UPDATE_AUTOMATION_STEP = "UPDATE_AUTOMATION_STEP";
export const REMOVE_AUTOMATION_STEP = "REMOVE_AUTOMATION_STEP";
export const REORDER_AUTOMATION_STEP = "REORDER_AUTOMATION_STEP";
export const RESET_AUTOMATION_STEPS = "RESET_AUTOMATION_STEPS";

// End Webhooks module
export const FETCH_END_WEBHOOKS = "FETCH_END_WEBHOOKS";
export const FETCH_END_WEBHOOK_BY_ID = "FETCH_END_WEBHOOK_BY_ID";
export const CREATE_END_WEBHOOK = "CREATE_END_WEBHOOK";
export const UPDATE_END_WEBHOOK = "UPDATE_END_WEBHOOK";
export const REMOVE_END_WEBHOOK = "REMOVE_END_WEBHOOK";

// Custom fields module
export const FETCH_CUSTOM_FIELDS = "FETCH_CUSTOM_FIELDS";
export const CREATE_CUSTOM_FIELD = "CREATE_CUSTOM_FIELD";
export const UPDATE_CUSTOM_FIELD = "UPDATE_CUSTOM_FIELD";
export const REMOVE_CUSTOM_FIELD = "REMOVE_CUSTOM_FIELD";

// Textodeal offers module
export const FETCH_TEXTODEAL_OFFERS = "FETCH_TEXTODEAL_OFFERS";
export const CREATE_TEXTODEAL_OFFER = "CREATE_TEXTODEAL_OFFER";
export const UPDATE_TEXTODEAL_OFFER = "UPDATE_TEXTODEAL_OFFER";
export const REMOVE_TEXTODEAL_OFFER = "REMOVE_TEXTODEAL_OFFER";

// Textodeal provider module
export const FETCH_TEXTODEAL_PROVIDER = "FETCH_TEXTODEAL_PROVIDER";
