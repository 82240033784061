import API from "@/http/http-client";

export const ReportsService = {
  async fetchReports(filter: any): Promise<any> {
    const {headers, data} = await API.get('/dog/messages?forReport=true&currentPage=1&perPage=10', {
      params: filter
    });
    const total = headers['x-total-count']
    return {data, total};
  },

  async filterReports(filter: any): Promise<any> {
    const {headers, data} = await API.get('/dog/messages', {
      params: filter
    });
    const total = headers['x-total-count']
    return {data, total};
  },

  async exportReports(filter: any): Promise<any> {
    const {data} = await API.post('/dog/export-csv/messages', filter);
    return data;
  },
};
