import WidgetItemInterface from "@/interfaces/widget-item.interface";

export const WIDGET_ITEM_1: WidgetItemInterface = {
  widgetId: 'widget-1',
  name: 'Home Page',
  url: 'http://tourika.com/',
  message: 'Confused which one to choose? Leave your mobile number and get direct access to our hotel experts by text messaging.',
  smsMessage: 'Thank you fore reaching out to us. Let us know if you have questions about hotels and we\'ll get back to your with answers. Here is the page you were on: {{url}}',
  parameterType: 'URL Starts with'
};

export const WIDGET_ITEM_2: WidgetItemInterface = {
  widgetId: 'widget-2',
  name: 'French Home Page',
  url: 'http://tourika.com/',
  parameterType: 'URL Starts with'
};

export const WIDGET_ITEM_3: WidgetItemInterface = {
  widgetId: 'widget-3',
  name: 'English Hotel Details',
  url: 'http://tourika.com/',
  parameterType: 'URL Starts with'
};

export const WIDGET_ITEMS: WidgetItemInterface[] = [
  WIDGET_ITEM_1,
  WIDGET_ITEM_2,
  WIDGET_ITEM_3
];
