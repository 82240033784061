const STAGING = {
  BASE_URL: "https://beta-gateway.textodog.com/v1/",
  PUSHER_APP_KEY: "c677873383563f3531f3"
};

const PRODUCTION = {
  BASE_URL: "https://gateway.textodog.com/v1/",
  PUSHER_APP_KEY: "06286b345aeddc561eb9"
};

export const ENV =
  location.href.includes("beta") || process.env.NODE_ENV === "development" ? STAGING : PRODUCTION;
