export interface IStripePrice {
  id: string;
  amount: number;
  currency: StripeCurrency;
  recurringInterval: string;
  recurringIntervalCount: number;
  nbAgentAccount: number;
}

export enum StripeCurrency {
  USD = 'usd',
  CAD = 'cad'
}
