





































































import { Component, Vue } from "vue-property-decorator";
import ComposeMessagePopup from "@/components/inbox/ComposeMessagePopup.vue";
import { Getter } from "vuex-class";
import * as actionTypes from "@/store/action-types";
import IAgentAccount from "@/interfaces/models/agent-account.interface";
import { ContactsService } from "@/http/services/contacts.service";
import { NotificationType } from "@/interfaces/models/INotification";

@Component({
  components: { ComposeMessagePopup }
})
export default class NewItemPopover extends Vue {
  @Getter agent!: IAgentAccount;
  @Getter isAuth!: boolean;
  isMenuOpened: boolean = false;
  isShowComposeMessagePopup: boolean = false;

  get isExpired(): boolean {
    return this.agent?.businessAccount?.isExpired;
  }

  openComposeMessagePopup() {
    this.isShowComposeMessagePopup = true;
  }

  closeComposeMessagePopup(): void {
    this.isShowComposeMessagePopup = false;
  }

  openNewContactDrawer() {
    this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_CREATE);
  }

  /**
   * Import Contacts
   */
  fileToImport: string = "";
  loading: boolean = false;
  openDialog: boolean = false;
  failureData: Array<string> = [];

  openImportFileReport() {
    this.openDialog = true;
  }

  closeImportFileReport() {
    this.openDialog = false;
  }

  selectFile() {
    document.getElementById("csv-file-input")?.click();
  }

  resetFileInput() {
    this.$nextTick(() => {
      const fileInput = this.$refs.fileInput as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    });
  }

  async handleFileSelection(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.loading = true;
      const formData = new FormData();
      formData.append("file", file);
      await ContactsService.importContacts(formData)
        .then(response => {
          this.failureData = response.failureData;
          if (response.failureNumber === 0) {
            this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
              text: "All contacts have been uploaded successfully!",
              type: NotificationType.SUCCESS
            });
          } else {
            this.openImportFileReport();
          }

          this.resetFileInput();
          this.loading = false;
        })
        .catch(error => {
          this.resetFileInput();
          this.loading = false;
          this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
            text: "File upload failed",
            type: NotificationType.ERROR
          });
        });
    }
  }
}
