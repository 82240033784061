










import { Component, Prop, Vue } from "vue-property-decorator";
import icons from "@/common/utils/icons.json";

@Component
export default class Icon extends Vue {
  @Prop({ type: String, required: true }) iconName!: string;

  icons = icons;
}
