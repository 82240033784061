import {ReportsStateInterface, RootState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import * as mutationTypes from "@/store/mutation-types";
import * as actionTypes from "@/store/action-types";
import IReport from "@/interfaces/models/report.interface";
import {ReportsService} from "@/http/services/reports.service";
import {NotificationType} from "@/interfaces/models/INotification";

export const state: ReportsStateInterface = {
  reports: [],
  reports_ct: 0,
  currentReport: null,
  newReport: 0,
};

export const getters: GetterTree<ReportsStateInterface, RootState> = {
  reports: (state: ReportsStateInterface) => state.reports,
  // .sort((a, b) => `${a.fromNumber}`.toLowerCase() >`${b.fromNumber}`.toLowerCase() ? 1 : -1),
  reports_ct: (state: ReportsStateInterface) => state.reports_ct,
  newReport: (state: ReportsStateInterface) => state.newReport,
  currentReport: (state: ReportsStateInterface) => state.currentReport,
};

export const mutations: MutationTree<ReportsStateInterface> = {
  [mutationTypes.SET_REPORTS]: (state: ReportsStateInterface, reports: IReport[]) => {
    state.reports = reports;
    state.newReport = 0;
  },

  [mutationTypes.SET_REPORTS_CT]: (state: ReportsStateInterface, reports_ct: number) => {
    state.reports_ct = reports_ct;
  },

  [mutationTypes.SET_CURRENT_REPORT]: (state: ReportsStateInterface, report: IReport | null) => {
    state.currentReport = report;
  },

  [mutationTypes.UPDATE_REPORT]: (state: ReportsStateInterface, updatedReport: IReport) => {
    state.reports = state.reports
      .map((report: IReport) => report.id === updatedReport.id ? updatedReport : report);
  },
  [mutationTypes.UPDATE_LATEST_REPORT]: (state: ReportsStateInterface, newMessage: boolean) => {
    state.newReport = 1; //if newReport is true, should refresh the reports
  }
};

export const actions: ActionTree<ReportsStateInterface, RootState> = {
  [actionTypes.FETCH_REPORTS]: async ({commit}: ActionContext<ReportsStateInterface, RootState>, payload: { name?: string, tags?: string }) => {
    const {data, total} = await ReportsService.fetchReports(payload);
    const reports = data.map((report: any) => ({...report, ...{
        tags: report.tags || [],
        customFields: report.customFields || []
      }
    }));
    commit(mutationTypes.SET_REPORTS, reports);
    commit(mutationTypes.SET_REPORTS_CT, Number(total));
  },
};

export const reports: Module<ReportsStateInterface, RootState> = {
  state,
  getters,
  mutations,
  actions
};
