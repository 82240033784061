import * as mutationTypes from '@/store/mutation-types';
import * as actionTypes from '@/store/action-types';
import { AutomationsState, RootState } from '@/interfaces/states';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IAutomation } from '@/interfaces/models/IAutomation';
import { AutomationService } from '@/http/services/automation.service';
import { ICreateAutomation } from '@/interfaces/requests/ICreateAutomation';
import { IUpdateAutomation } from '@/interfaces/requests/IUpdateAutomation';

export const state: AutomationsState = {
  automations     : [],
  activeAutomation: null,
};

export const getters: GetterTree<AutomationsState, RootState> = {
  automations     : (state: AutomationsState) => state.automations,
  activeAutomation: (state: AutomationsState) => state.activeAutomation,
};

export const mutations: MutationTree<AutomationsState> = {
  [mutationTypes.SET_AUTOMATIONS]: (state: AutomationsState, automations: IAutomation[]) => {
    state.automations = automations;
  },

  [mutationTypes.SET_ACTIVE_AUTOMATION]: (state: AutomationsState, automation: IAutomation) => {
    state.activeAutomation = automation;
  },
};

export const actions: ActionTree<AutomationsState, RootState> = {
  [actionTypes.FETCH_AUTOMATIONS]: async ({commit}: ActionContext<AutomationsState, RootState>, type: string | null) => {
    const automations = await AutomationService.fetchAutomations(type);
    commit(mutationTypes.SET_AUTOMATIONS, automations);
  },

  [actionTypes.FETCH_AUTOMATION_BY_ID]: async ({commit}: ActionContext<AutomationsState, RootState>, automationId: number) => {
    const automation = await AutomationService.fetchAutomation(automationId);
    commit(mutationTypes.SET_ACTIVE_AUTOMATION, automation);
  },

  [actionTypes.CREATE_AUTOMATION]: async ({dispatch}: ActionContext<AutomationsState, RootState>, payload: ICreateAutomation): Promise<IAutomation> => {
    const createdAutomation = await AutomationService.createAutomation(payload);
    await dispatch(actionTypes.FETCH_AUTOMATIONS);

    return createdAutomation;
  },

  [actionTypes.UPDATE_AUTOMATION]: async ({
                                            dispatch,
                                            state,
                                            commit
                                          }: ActionContext<AutomationsState, RootState>, payload: IUpdateAutomation) => {
    const updatedAutomation = await AutomationService.updateAutomation(payload);
    await dispatch(actionTypes.FETCH_AUTOMATIONS);

    if (state.activeAutomation?.id === updatedAutomation.id) {
      commit(mutationTypes.SET_ACTIVE_AUTOMATION, updatedAutomation);
    }
  },

  [actionTypes.REMOVE_AUTOMATION]: async ({dispatch}: ActionContext<AutomationsState, RootState>, automationId: number) => {
    await AutomationService.removeAutomation(automationId);
    await dispatch(actionTypes.FETCH_AUTOMATIONS);
  },

  [actionTypes.ENABLE_AUTOMATION]: async ({dispatch}: ActionContext<AutomationsState, RootState>, automationId: number) => {
    await AutomationService.enableAutomation(automationId);
    await dispatch(actionTypes.FETCH_AUTOMATIONS);
  },

  [actionTypes.DISABLE_AUTOMATION]: async ({dispatch}: ActionContext<AutomationsState, RootState>, automationId: number) => {
    await AutomationService.disableAutomation(automationId);
    await dispatch(actionTypes.FETCH_AUTOMATIONS);
  },

  [actionTypes.RESET_ACTIVE_AUTOMATION]: async ({commit}: ActionContext<AutomationsState, RootState>) => {
    commit(mutationTypes.SET_ACTIVE_AUTOMATION, null);
  },
};

export const automations: Module<AutomationsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
