import {BusinessAccountState, RootState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import {BusinessAccountService} from "@/http/services/business-account.service";
import {IAddress} from "@/interfaces/models/location.interface";
import {ProductService} from "@/http/services/product.service";

export const state: BusinessAccountState = {
  businessAccounts: [],
  currentBusinessAccount: null,
};

export const getters: GetterTree<BusinessAccountState, RootState> = {
  businessAccounts: (state: BusinessAccountState) => state.businessAccounts,
  currentBusinessAccount: (state: BusinessAccountState) => state.currentBusinessAccount,
};

export const mutations: MutationTree<BusinessAccountState> = {
  [mutationTypes.SET_BUSINESS_ACCOUNTS]: (state: BusinessAccountState, businessAccounts: IBusinessAccount[]) => {
    state.businessAccounts = businessAccounts;
  },
  [mutationTypes.SET_CURRENT_BUSINESS_ACCOUNT]: (state: BusinessAccountState, businessAccount: IBusinessAccount) => {
    state.currentBusinessAccount = businessAccount;
  },
  [mutationTypes.UPDATE_BUSINESS_ACCOUNT]: (state: BusinessAccountState, updatedBusinessAccount: IBusinessAccount) => {
    state.businessAccounts = state.businessAccounts
      .map((businessAccount: IBusinessAccount) => businessAccount.id === updatedBusinessAccount.id ? updatedBusinessAccount : businessAccount);
  }
};

export const actions: ActionTree<BusinessAccountState, RootState> = {
  [actionTypes.FETCH_BUSINESS_ACCOUNTS]: async ({
                                                  commit,
                                                }: ActionContext<BusinessAccountState, RootState>) => {
    const businessAccounts = await BusinessAccountService.fetchBusinessAccounts();
    commit(mutationTypes.SET_BUSINESS_ACCOUNTS, businessAccounts);
    },
  [actionTypes.FETCH_BUSINESS_ACCOUNT_BY_ID]: async ({
                                                 commit,
                                                 rootGetters
                                               }: ActionContext<BusinessAccountState, RootState>) => {
    const businessAccount = await BusinessAccountService.fetchBusinessAccount(rootGetters.agent.businessAccount.id);
    commit(mutationTypes.SET_CURRENT_BUSINESS_ACCOUNT, businessAccount);
  },

  [actionTypes.UPDATE_BUSINESS_ACCOUNT]: async ({
                                                  commit,
                                                  state
                                                }: ActionContext<BusinessAccountState, RootState>, updateData: Partial<IBusinessAccount>) => {
    const updatedBusinessAccount = await BusinessAccountService.updateBusinessAccount(updateData, updateData.id ?? state.currentBusinessAccount!.id);
    commit(mutationTypes.UPDATE_BUSINESS_ACCOUNT, updatedBusinessAccount);
    commit(mutationTypes.SET_CURRENT_BUSINESS_ACCOUNT, updatedBusinessAccount);
  },

  [actionTypes.UPDATE_BUSINESS_ACCOUNT_ADDRESS]: async ({
                                                  commit,
                                                  state
                                                }: ActionContext<BusinessAccountState, RootState>, address: IAddress) => {
    const updateAccount = await BusinessAccountService.updateBusinessAccountAddress(state.currentBusinessAccount!.id, address);
    commit(mutationTypes.SET_CURRENT_BUSINESS_ACCOUNT, updateAccount);
  },

  [actionTypes.UPDATE_SUBSCRIPTION]: async ({
                                                          dispatch,
                                                          state
                                                        }: ActionContext<BusinessAccountState, RootState>, priceId: string) => {
    await ProductService.updateSubscription(state.currentBusinessAccount!.id, priceId);
  },

  [actionTypes.ENABLE_BUSINESS_ACCOUNT]: async ({dispatch}: ActionContext<BusinessAccountState, RootState>, businessAccountId: number) => {
    await BusinessAccountService.enableBusinessAccount(businessAccountId);
    await dispatch(actionTypes.FETCH_BUSINESS_ACCOUNTS);
  },

  [actionTypes.DISABLE_BUSINESS_ACCOUNT]: async ({dispatch}: ActionContext<BusinessAccountState, RootState>, businessAccountId: number) => {
    await BusinessAccountService.disableBusinessAccount(businessAccountId);
    await dispatch(actionTypes.FETCH_BUSINESS_ACCOUNTS);
  },
};

export const businessAccount: Module<BusinessAccountState, RootState> = {
  state,
  getters,
  mutations,
  actions,
}
