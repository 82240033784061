import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';
import {RootState, TextodealProvidersState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import ITextodealProvider from '@/interfaces/models/textodeal-provider.interface';
import { TextodealProviderService } from '@/http/services/textodeal-provider.service';

export const state: TextodealProvidersState = {
  currentTextodealProvider: null,
};

export const getters: GetterTree<TextodealProvidersState, RootState> = {
  currentTextodealProvider: (state: TextodealProvidersState) => state.currentTextodealProvider,
};

export const mutations: MutationTree<TextodealProvidersState> = {
  [mutationTypes.SET_CURRENT_TEXTODEAL_PROVIDER]: (state: TextodealProvidersState, textodealProvider: ITextodealProvider) => {
    state.currentTextodealProvider = textodealProvider;
  },
};

export const actions: ActionTree<TextodealProvidersState, RootState> = {
  [actionTypes.FETCH_TEXTODEAL_PROVIDER]: async ({commit}: ActionContext<TextodealProvidersState, RootState>, businessAccountId: number) => {
    const textodealProvider = await TextodealProviderService.fetchTextodealProvider(businessAccountId);
    commit(mutationTypes.SET_CURRENT_TEXTODEAL_PROVIDER, textodealProvider);
  },
};

export const textodealProviders: Module<TextodealProvidersState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
