import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';
import {RootState, TextodealOffersState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import { TextodealOffersService } from '@/http/services/textodeal-offer.service';
import ITextodealOffer from '@/interfaces/models/textodeal-offer.interface';

export const state: TextodealOffersState = {
  textodealOffers: [],
};

export const getters: GetterTree<TextodealOffersState, RootState> = {
  textodealOffers: (state: TextodealOffersState) => state.textodealOffers,
};

export const mutations: MutationTree<TextodealOffersState> = {
  [mutationTypes.SET_TEXTODEAL_OFFERS]: (state: TextodealOffersState, textodealOffers: ITextodealOffer[]) => {
    state.textodealOffers = textodealOffers;
  },
  [mutationTypes.CREATE_TEXTODEAL_OFFER]: (state: TextodealOffersState, textodealOffer: ITextodealOffer) => {
    state.textodealOffers = state.textodealOffers.concat(textodealOffer);
  },
  [mutationTypes.UPDATE_TEXTODEAL_OFFER]: (state: TextodealOffersState, textodealOffer: ITextodealOffer) => {
    state.textodealOffers = state.textodealOffers.map(r => r.id === textodealOffer.id ? textodealOffer : r);
  },
  [mutationTypes.REMOVE_TEXTODEAL_OFFER]: (state: TextodealOffersState, textodealOfferToRemove: number) => {
    state.textodealOffers = state.textodealOffers.filter(r => r.id !== textodealOfferToRemove);
  },
};

export const actions: ActionTree<TextodealOffersState, RootState> = {
  [actionTypes.FETCH_TEXTODEAL_OFFERS]: async ({commit}: ActionContext<TextodealOffersState, RootState>) => {
    const textodealOffers = await TextodealOffersService.fetchOffers();
    commit(mutationTypes.SET_TEXTODEAL_OFFERS, textodealOffers);
  },

  [actionTypes.UPDATE_TEXTODEAL_OFFER]: async ({commit}: ActionContext<TextodealOffersState, RootState>, textodealOffer: ITextodealOffer) => {
    const updatedTextodealOffer = await TextodealOffersService.updateOffer(textodealOffer);
    commit(mutationTypes.UPDATE_TEXTODEAL_OFFER, updatedTextodealOffer);
  },

  [actionTypes.CREATE_TEXTODEAL_OFFER]: async ({commit}: ActionContext<TextodealOffersState, RootState>, textodealOffer: ITextodealOffer) => {
    const createdTextodealOffer = await TextodealOffersService.createOffer(textodealOffer);
    commit(mutationTypes.CREATE_TEXTODEAL_OFFER, createdTextodealOffer);
  },

  [actionTypes.REMOVE_TEXTODEAL_OFFER]: async ({commit}: ActionContext<TextodealOffersState, RootState>, textodealOfferId: number) => {
    await TextodealOffersService.removeOffer(textodealOfferId);
    commit(mutationTypes.REMOVE_TEXTODEAL_OFFER, textodealOfferId);
  },
};

export const textodealOffers: Module<TextodealOffersState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
