import router from '@/router';

export const PushNotificationService = {
  isAllowed: false,

  async requestPushNotificationPermission(): Promise<void> {
    if (!("Notification" in window)) {
      return;
    }
    const result = await Notification.requestPermission();
    this.isAllowed = result === "granted";
  },

  async showPushNotification(title: string, text: string, profilePhoto: string, imageURL: any = '', conversationId: number) {
    if (!this.isAllowed) {
      return;
    }

    const audio = new Audio('/notification_sound.mp3');
    await audio.play();

    const notification = new Notification(title, {
      body: text ?? '',
      icon: profilePhoto,
      silent: true,
      image: imageURL ?? null,
    });

    notification.onclick = async () => {
      window.focus();
      await router.push(`/inbox/${conversationId}`)
    }
  }
}
