import { DepartmentsState, RootState } from '@/interfaces/states';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { SET_DEPARTMENTS } from '@/store/mutation-types';
import { IDepartment } from '@/interfaces/models/IDepartment';
import {
  CREATE_DEPARTMENT,
  FETCH_DEPARTMENTS,
  REMOVE_DEPARTMENT,
  UPDATE_DEPARTMENT
} from '@/store/action-types';
import { DepartmentService } from '@/http/services/department.service';
import { ICreateDepartment } from '@/interfaces/requests/ICreateDepartment';

export const state: DepartmentsState = {
  departments: []
};

export const getters: GetterTree<DepartmentsState, RootState> = {
  departments: (state: DepartmentsState) => state.departments,
};

export const mutations: MutationTree<DepartmentsState> = {
  [SET_DEPARTMENTS]: (state: DepartmentsState, departments: IDepartment[]) => {
    state.departments = departments;
  },
};

export const actions: ActionTree<DepartmentsState, RootState> = {
  [FETCH_DEPARTMENTS]: async ({commit}: ActionContext<DepartmentsState, RootState>) => {
    const departments = await DepartmentService.fetchDepartments();
    commit(SET_DEPARTMENTS, departments.sort(d => d.isDeletable ? -1 : 1));
  },

  [CREATE_DEPARTMENT]: async ({dispatch}: ActionContext<DepartmentsState, RootState>, payload: ICreateDepartment) => {
    await DepartmentService.createDepartment(payload);
    await dispatch(FETCH_DEPARTMENTS);
  },

  [UPDATE_DEPARTMENT]: async ({dispatch}: ActionContext<DepartmentsState, RootState>, payload: any) => {
    await DepartmentService.updateDepartment(payload.id, payload.updateData);
    await dispatch(FETCH_DEPARTMENTS);
  },

  [REMOVE_DEPARTMENT]: async ({dispatch}: ActionContext<DepartmentsState, RootState>, departmentId: number) => {
    await DepartmentService.removeDepartment(departmentId);
    await dispatch(FETCH_DEPARTMENTS);
  },
};

export const departments: Module<DepartmentsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
