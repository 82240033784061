




























import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Stack, StackItem } from "vue-stack-grid";
import InfiniteLoading from "vue-infinite-loading";
import { Getter } from "vuex-class";
import * as actionTypes from "@/store/action-types";
import ISavedReply from "@/interfaces/models/saved-reply.interface";

@Component({
  components: { Stack, StackItem, InfiniteLoading }
})
export default class SavedRepliesPopup extends Vue {
  @Prop({ type: Boolean }) compose!: boolean;
  @Getter savedReplies!: ISavedReply[];

  showPopup: boolean = false;
  gf: GiphyFetch | null = null;
  gifs: any[] = [];
  term: string = "";
  timeoutId: number = 0;
  currentOffset = 0;
  isLoading: boolean = false;

  @Emit("select-reply")
  selectReply(reply: ISavedReply): ISavedReply {
    this.showPopup = false;
    return reply;
  }

  async mounted(): Promise<void> {
    if (!this.savedReplies.length) {
      await this.$store.dispatch(actionTypes.FETCH_SAVED_REPLIES);
    }
  }

  handleClickOutside(): void {
    if (this.showPopup) {
      this.showPopup = false;
    }
  }
}
