// Auth module
export const SET_TOKEN = "SET_TOKEN";
export const SET_AGENT = "SET_AGENT";
export const RESET_AUTH = "RESET_AUTH";
export const SET_AS_ADMIN = "SET_AS_ADMIN";

// Conversation module
export const SET_OPENED_CONVERSATIONS = "SET_OPENED_CONVERSATIONS";
export const SET_CLOSED_CONVERSATIONS = "SET_CLOSED_CONVERSATIONS";
export const SET_MORE_OPENED_CONVERSATIONS = "SET_MORE_OPENED_CONVERSATIONS";
export const SET_MORE_CLOSED_CONVERSATIONS = "SET_MORE_CLOSED_CONVERSATIONS";
export const SET_CURRENT_CONVERSATION = "SET_CURRENT_CONVERSATION";
export const UPDATE_LATEST_MESSAGE = "UPDATE_LATEST_MESSAGE";

// Messages module
export const SET_MESSAGES = "SET_MESSAGES";
export const CONCAT_MESSAGES = "CONCAT_MESSAGES";
export const CONCAT_NEW_MESSAGE = "CONCAT_NEW_MESSAGE";

// Contacts module
export const SET_CONTACTS = "SET_CONTACTS";
export const SET_ALL_CONTACTS = "SET_ALL_CONTACTS";
export const SET_CONTACTS_CT = "SET_CONTACTS_CT";
export const SET_CURRENT_CONTACT = "SET_CURRENT_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";

// Reports module
export const SET_REPORTS = "SET_REPORTS";
export const SET_REPORTS_CT = "SET_REPORTS_CT";
export const SET_CURRENT_REPORT = "SET_CURRENT_REPORT";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const CREATE_REPORT = "CREATE_REPORT";
export const UPDATE_LATEST_REPORT = "UPDATE_LATEST_REPORT";

// Tags module
export const SET_TAGS = "SET_TAGS";

// Identifiers module
export const SET_IDENTIFIER = "SET_IDENTIFIER";

// Contact edit drawer module
export const SET_CONTACT_DRAWER_STATE = "SET_CONTACT_DRAWER_STATE";
export const SET_CONVERSATION_STATE = "SET_CONVERSATION_STATE";

// Business account module
export const SET_BUSINESS_ACCOUNTS = "SET_BUSINESS_ACCOUNTS";
export const SET_CURRENT_BUSINESS_ACCOUNT = "SET_CURRENT_BUSINESS_ACCOUNT";
export const UPDATE_BUSINESS_ACCOUNT = "UPDATE_BUSINESS_ACCOUNT";

// Snackbar module
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

// Agent module
export const SET_AGENTS = "SET_AGENTS";
export const UPDATE_AGENTS = "UPDATE_AGENTS";
export const ADD_AGENT = "ADD_AGENT";

// Billing module
export const SET_INVOICES = "SET_INVOICES";
export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";
export const SET_MESSAGE_COUNT_HISTORY = "SET_MESSAGE_COUNT_HISTORY";

// Stripe product module
export const SET_STRIPE_PRODUCTS = "SET_STRIPE_PRODUCTS";
export const SET_STRIPE_PRICES = "SET_STRIPE_PRICES";
export const SET_PROMO_CODE_STATE = "SET_PROMO_CODE_STATE";

// Saved replies module
export const SET_SAVED_REPLIES = "SET_SAVED_REPLIES";
export const UPDATE_SAVED_REPLY = "UPDATE_SAVED_REPLY";
export const APPEND_SAVED_REPLY = "APPEND_SAVED_REPLY";
export const REMOVE_SAVED_REPLY = "REMOVE_SAVED_REPLY";

// Departments module
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";

// Automations module
export const SET_AUTOMATIONS = "SET_AUTOMATIONS";
export const SET_ACTIVE_AUTOMATION = "SET_ACTIVE_AUTOMATION";

// End Webhooks module
export const SET_END_WEBHOOKS = "SET_END_WEBHOOKS";
export const SET_END_WEBHOOK = "SET_END_WEBHOOK";

// Automation steps module
export const SET_AUTOMATION_STEPS = "SET_AUTOMATION_STEPS";

// Custom fields module
export const SET_CUSTOM_FIELDS = "SET_CUSTOM_FIELDS";

// Textodeal offer module
export const SET_TEXTODEAL_OFFERS = "SET_TEXTODEAL_OFFERS";
export const CREATE_TEXTODEAL_OFFER = "CREATE_TEXTODEAL_OFFER";
export const UPDATE_TEXTODEAL_OFFER = "UPDATE_TEXTODEAL_OFFER";
export const REMOVE_TEXTODEAL_OFFER = "REMOVE_TEXTODEAL_OFFER";

// Textodeal provider module
export const SET_CURRENT_TEXTODEAL_PROVIDER = "SET_CURRENT_TEXTODEAL_PROVIDER";
