























































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { email, required } from "vuelidate/lib/validators";
import IContact, { IContactCustomField } from "@/interfaces/models/contact.interface";
import ImageDropArea from "@/common/reusable-components/ImageDropArea.vue";
import ITag from "@/interfaces/models/tag.interface";
import * as actionTypes from "@/store/action-types";
import { Getter } from "vuex-class";
import { NotificationType } from "@/interfaces/models/INotification";
import { ICustomField } from "@/interfaces/models/ICustomField";

@Component({
  components: { ImageDropArea }
})
export default class EditContactForm extends Vue {
  @Prop({ type: Object, default: null }) contact!: IContact | null;
  @Getter("tags") tags!: ITag[];
  @Getter customFields!: ICustomField[];

  firstName: string = "";
  lastName: string = "";

  @Validate({ email })
  email: string = "";

  @Validate({ required })
  phoneNumber: string = "";

  title: string = "";
  selectedTags: ITag[] = [];
  photo: string | null = null;
  contactCustomFields: IContactCustomField[] = [];

  isSaving: boolean = false;
  photoWasChanged: boolean = false;
  currentPhoto: string = "";

  setContactFields(): void {
    this.firstName = this.contact?.firstName || "";
    this.lastName = this.contact?.lastName || "";
    this.phoneNumber = this.contact?.phoneNumber
      ? this.contact.phoneNumber.slice(2).replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, "($1) $2-$3")
      : "";
    this.email = this.contact?.email || "";
    this.title = this.contact?.title || "";
    this.selectedTags = this.contact?.tags ? [...this.contact.tags] : [];
    this.contactCustomFields = this.contactCustomFields.map((customField: IContactCustomField) => {
      const fieldValue = this.contact?.customFields.find(({ id }) => id === customField.id);
      let newValue: any = [""];
      if (fieldValue && fieldValue?.value.length !== 0) {
        newValue = fieldValue?.value;
      }
      return {
        id: customField.id,
        value: newValue
      };
    });
  }

  @Emit("close-drawer")
  closeDrawer(): void {}

  async mounted(): Promise<void> {
    this.setContactFields();

    await Promise.all([
      !this.tags.length ? this.$store.dispatch(actionTypes.FETCH_TAGS) : () => {},
      !this.customFields.length ? this.$store.dispatch(actionTypes.FETCH_CUSTOM_FIELDS) : () => {}
    ]);

    this.contactCustomFields = this.customFields.map(customField => ({
      id: customField.id,
      value: [""]
    }));

    this.setContactFields();
  }

  showContactImage(): void {
    let image = "";
    if (this.contact) image = this.contact.photo;
    else {
      image = this.photo || "";
    }
    this.currentPhoto = image;
  }

  getCustomFieldName(id: number): string {
    return this.customFields.find(customField => customField.id === id)?.name || "";
  }

  addCustomField(inx: number) {
    this.contactCustomFields[inx].value.push("");
  }

  removeCustomField(inx: number, index: number) {
    this.contactCustomFields[inx].value.splice(index, 1);
    if (this.contactCustomFields[inx].value.length === 0) {
      this.contactCustomFields[inx].value.push("");
    }
  }
  /**
   * For contact creating we should pass null as default value
   * For contact edit if we want to remove value we should pass empty string, otherwise value will not be removed
   */
  async saveUserInfo(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    const tags = this.selectedTags.map((tag: ITag) => (tag?.id ? tag.name : tag));

    try {
      this.isSaving = true;

      // predefine contact custom fields
      let tmp_customFields: any[] = [];
      for (let index = 0; index < this.contactCustomFields.length; index++) {
        const element: any = this.contactCustomFields[index];
        tmp_customFields.push({
          id: element.id,
          value: element.value.filter((e: any) => e !== "" && e !== null)
        });
      }

      if (this.contact?.id) {
        const contact = {
          tags,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: `+1${this.phoneNumber.replace(/[() -]/g, "")}`,
          email: this.email || "",
          title: this.title || "",
          photo: this.photo || "",
          customFields: tmp_customFields
        };

        if (!this.photoWasChanged) {
          delete contact["photo"];
        }
        await this.$store.dispatch(actionTypes.UPDATE_CONTACT, { ...contact, id: this.contact.id });
      } else {
        const contact = {
          tags,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: `+1${this.phoneNumber.replace(/[() -]/g, "")}`,
          email: this.email || null,
          title: this.title || null,
          photo: this.photo || "",
          customFields: tmp_customFields
        };

        if (!this.photoWasChanged) {
          delete contact["photo"];
        }

        await this.$store.dispatch(actionTypes.CREATE_CONTACT, contact);
      }
      this.closeDrawer();
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isSaving = false;
    }
  }

  scrollBottom(): void {
    document.querySelector(".actions")?.scrollIntoView();
  }

  handleFileSelecting(file: string | null): void {
    this.photo = file;
    this.photoWasChanged = true;
    // this.showContactImage()
  }

  get dropAreaText(): string {
    return this.$vuetify.breakpoint.xs ? "upload image" : "select or drag image";
  }

  get titleText(): string {
    return this.contact?.id ? "Edit Contact" : "New Contact";
  }

  get savingBtnText(): string {
    return this.contact?.id ? "Update" : "Save";
  }
}
