import IContact from "@/interfaces/models/contact.interface";
import API from "@/http/http-client";

export const ContactsService = {
  async fetchContacts(filter: any = {}): Promise<any> {
    if (!filter["currentPage"]) {
      filter["currentPage"] = 1;
    }
    if (!filter["perPage"] && filter["perPage"] !== 0) {
      filter["perPage"] = 10;
    }
    const { headers, data } = await API.get("/dog/contacts", {
      params: filter
    });
    const total = headers["x-total-count"];
    return { data, total };
  },

  async createContact(contact: IContact): Promise<IContact> {
    const { data } = await API.post("/dog/contacts", { ...contact });
    return data;
  },

  async removeContacts(ids: number[]): Promise<{ data: { errorContacts: string[] } }> {
    return await API.delete("/dog/contacts", {
      params: { ids }
    });
  },

  async removeContact(id: number): Promise<{ data: { message: string; code: string } }> {
    return await API.delete(`/dog/contacts/${id}`);
  },

  async getContactBydId(id: number): Promise<IContact> {
    const { data } = await API.get(`/dog/contacts/${id}`);
    return data;
  },

  async updateContact(contact: IContact): Promise<IContact> {
    const { data } = await API.patch(`/dog/contacts/${contact.id}`, { ...contact });
    return data;
  },

  async removeTagForMultipleContacts(payload: object): Promise<void> {
    await API.patch(`/dog/contacts/remove-tag`, payload);
  },

  async addTagForMultipleContacts(payload: object): Promise<void> {
    await API.patch(`/dog/contacts/add-tag`, payload);
  },

  async exportContacts(filter: any): Promise<any> {
    const { data } = await API.post("/dog/export-csv/contacts", filter);
    return data;
  },

  async importContacts(file: FormData): Promise<any> {
    const { data } = await API.post("/dog/contacts/import", file);
    return data;
  }
};
