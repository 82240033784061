

























































import {Component, Vue, Emit, Prop} from 'vue-property-decorator';
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

@Component({
  components: {Cropper}
})
export default class ImageCropperModal extends Vue {
  @Prop({type: Boolean, required: true}) isOpen!: boolean;
  @Prop({required: true}) imageToCrop!: string;
  @Prop({required: true}) imageShape!: string;
  @Prop({required: true}) imageQuality!: number;
  base64Image: string = '';

  @Emit('cropped')
  saveCrop(): string {
    return this.base64Image;
  }

  change({canvas}: { canvas: HTMLCanvasElement }): void {
    this.base64Image = canvas.toDataURL('image/jpeg', this.imageQuality);
  }

  getStencilComponent() {
    return this.imageShape + '-stencil';
  }
}
