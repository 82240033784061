import {HeaderStateInterface, RootState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";

export const state: HeaderStateInterface = {
  backLink: '',
  countOfSelectedContacts: 0,
  title: ''
};

export const getters: GetterTree<HeaderStateInterface, RootState> = {
  getHeaderTitle: (state: HeaderStateInterface) => state.title,
  getHeaderBackLink: (state: HeaderStateInterface) => state.backLink,
  getHeaderCountOfSelectedContacts: (state: HeaderStateInterface) => state.countOfSelectedContacts,
}

export const mutations: MutationTree<HeaderStateInterface> = {
  setBackUrl: (state: HeaderStateInterface, backUrl: string) => {
    state.backLink = backUrl;
  },
  setHeaderTitle: (state: HeaderStateInterface, title: string) => {
    state.title = title;
  },
  setCountOfSelected: (state: HeaderStateInterface, countOfSelectedContacts: number) => {
    state.countOfSelectedContacts = countOfSelectedContacts;
  }
};

export const actions: ActionTree<HeaderStateInterface, RootState> = {
  updateBackUrl: ({commit}: ActionContext<HeaderStateInterface, RootState>, backUrl: string) => {
    commit('setBackUrl', backUrl);
  },
  updateHeaderTitle: ({commit}: ActionContext<HeaderStateInterface, RootState>, title: string) => {
    commit('setHeaderTitle', title);
  },
  updateCountOfSelected: ({commit}: ActionContext<HeaderStateInterface, RootState>, countOfSelected) => {
    commit('setCountOfSelected', countOfSelected);
  }
};

export const header: Module<HeaderStateInterface, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
