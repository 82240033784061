import IMessage from "@/interfaces/models/message.interface";
import API from "@/http/http-client";
import Pusher from "pusher-js";
import { ENV } from "@/common/utils/env";
import { PushNotificationService } from "@/common/services/push-notification.service";

export const MessageService = {
  pusher: new Pusher(ENV.PUSHER_APP_KEY, {
    cluster: "us2"
  }),

  async fetchMessages(conversationId: number): Promise<IMessage[]> {
    const { data } = await API.get(`/dog/messages`, {
      params: {
        conversationId,
        perPage: 50,
        currentPage: 1
      }
    });

    return data;
  },

  async sendMessage(message: IMessage): Promise<void> {
    const { data } = await API.post(`/dog/messages`, message);
    return data;
  },

  async authPusher(params: any): Promise<void> {
    const { data } = await API.post(`/dog/auth-broadcast`, params);
    return data;
  },

  async typingIndicator(params: any): Promise<void> {
    const { data } = await API.post(`/dog/auth-broadcast`, params);
    return data;
  },

  async subscribeToPusherUpdate(cb: Function): Promise<void> {
    const channel = this.pusher.subscribe("textodog-message");

    await PushNotificationService.requestPushNotificationPermission();

    channel.bind("messageReceived", (data: IMessage) => {
      cb(data);
    });
  },

  unsubscribeFromPusherUpdate(): void {
    this.pusher.unsubscribe("textodog-message");
  }
};
