import IAgentAccount from "@/interfaces/models/agent-account.interface";
import API from "@/http/http-client";
import { IVerifyToken } from "@/interfaces/requests/IVerifyToken";

(window as any).$axios = API;

export const AgentsService = {
  async fetchAgents(businessAccountId: number): Promise<IAgentAccount[]> {
    const {data} = await API.get(`/dog/agent-accounts`, {
      params: {businessAccountId}
    });
    return data;
  },

  async fetchAgent(agentId: number): Promise<IAgentAccount> {
    const {data} = await API.get(`/dog/agent-accounts/${agentId}`);
    return data;
  },

  async updateAgent(agentId: number, updateData: Partial<IAgentAccount>): Promise<IAgentAccount> {
    const {data} = await API.patch(`/dog/agent-accounts/${agentId}`, {...updateData});
    return data;
  },

  async createAgent(createData: object): Promise<IAgentAccount> {
    const {data} = await API.post(`/dog/agent-accounts`, {...createData});
    return data;
  },

  async removeAgent(id: number): Promise<void> {
    await API.delete(`/dog/agent-accounts/${id}`);
  },

  async activateAgent(agentId: number): Promise<void> {
    await API.patch(`/dog/agent-accounts/${agentId}/activate`);
  },

  async deactivateAgent(agentId: number): Promise<void> {
    await API.patch(`/dog/agent-accounts/${agentId}/deactivate`);
  },

  async resendInvitation(agentId: number): Promise<void> {
    await API.patch(`/dog/agent-accounts/${agentId}/resend-invitation`);
  },

  async verifyToken(verificationData: IVerifyToken): Promise<IAgentAccount> {
    const {data} = await API.post(`/dog/agent-accounts/verify-token`, verificationData);
    return data;
  },

  async passwordRecovery(email: string): Promise<void> {
    await API.post(`/dog/agent-accounts/recovery-password`, {email});
  },

  async resetPassword(agentId: number, password: string, confirmationPassword: string): Promise<void> {
    await API.patch(`/dog/agent-accounts/${agentId}/reset-password`, {password, confirmationPassword});
  }
}
