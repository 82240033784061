import Vue from 'vue';
import Icon from './Icon';
import TextodogLogo from '@/common/reusable-components/TextodogLogo';
import ContactAvatar from '@/common/reusable-components/ContactAvatar';
import ReportAvatar from '@/common/reusable-components/ReportAvatar';

Vue.component('Icon', Icon);
Vue.component('TextodogLogo', TextodogLogo);
Vue.component('ContactAvatar', ContactAvatar);
Vue.component('ReportAvatar', ReportAvatar);
