import {RootState, StripeProductState} from "@/interfaces/states";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import * as mutationTypes from '@/store/mutation-types';
import * as actionTypes from '@/store/action-types';
import {IStripePrice} from "@/interfaces/models/IStripePrice";
import {IStripeProduct} from "@/interfaces/models/IStripeProduct";
import {ProductService} from "@/http/services/product.service";

export const state: StripeProductState = {
  stripeProducts: [],
  stripePrices: [],
  isPromoCodeValid: false,
};

export const getters: GetterTree<StripeProductState, RootState> = {
  stripeProducts: (state: StripeProductState) => state.stripeProducts,
  stripePrices: (state: StripeProductState) => state.stripePrices,
  isPromoCodeValid: (state: StripeProductState) => state.isPromoCodeValid,
};

export const mutations: MutationTree<StripeProductState> = {
  [mutationTypes.SET_STRIPE_PRODUCTS]: (state: StripeProductState, products: IStripeProduct[]) => {
    state.stripeProducts = products;
  },

  [mutationTypes.SET_STRIPE_PRICES]: (state: StripeProductState, prices: IStripePrice[]) => {
    state.stripePrices = prices;
  },

  [mutationTypes.SET_PROMO_CODE_STATE]: (state: StripeProductState, value: boolean) => {
    state.isPromoCodeValid = value;
  },
};

export const actions: ActionTree<StripeProductState, RootState> = {
  [actionTypes.FETCH_STRIPE_PRODUCTS]: async ({commit}: ActionContext<StripeProductState, RootState>) => {
    const products = await ProductService.fetchProducts();
    commit(mutationTypes.SET_STRIPE_PRODUCTS, products);
  },

  [actionTypes.FETCH_STRIPE_PRICES]: async ({commit}: ActionContext<StripeProductState, RootState>, productId: string | null) => {
    const prices = await ProductService.fetchPrices(productId);
    commit(mutationTypes.SET_STRIPE_PRICES, prices);
  },
};

export const stripeProduct: Module<StripeProductState, RootState> = {
  state,
  getters,
  mutations,
  actions
};
