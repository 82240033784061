
























































import { Component, Emit, Vue } from "vue-property-decorator";
import UserSettings from "@/components/user-settings/UserSettings.vue";
import { Getter } from "vuex-class";
import * as actionTypes from "@/store/action-types";
import IBusinessAccount from "@/interfaces/models/business-account.interface";
import { Helpers } from "@/common/utils/helpers.js";
import IAgentAccount, { AgentRole } from "@/interfaces/models/agent-account.interface";
import store from "@/store";

@Component({
  components: { UserSettings }
})
export default class ProfileAvatar extends Vue {
  @Getter currentBusinessAccount!: IBusinessAccount;
  @Getter agent!: IAgentAccount;
  @Getter loggedInAsAdmin!: boolean;

  isShowUserSettings: boolean = false;

  profile(): void {
    this.isShowUserSettings = true;
  }

  get phoneNumber(): string {
    return Helpers.formatNumber(this.currentBusinessAccount.messageProxyNumber);
  }

  get profilePicture(): string | boolean {
    return Helpers.getProfilePhoto(this.agent);
  }

  get initials(): string {
    return Helpers.getInitials(this.agent);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  get isAdmin(): boolean {
    return this.agent?.role === AgentRole.ADMINISTRATOR;
  }

  @Emit("isLoading")
  logoutFromAdmin(): void {}

  async logout(): Promise<void> {
    if (this.loggedInAsAdmin) {
      this.logoutFromAdmin();
      await this.$store.dispatch(actionTypes.LOGOUT_FROM_ADMIN);
      // this.$store.dispatch(actionTypes.RESET);
      this.$router.go(0); // to reload routes history, so components will be rerendered
    } else {
      await this.$store.dispatch(actionTypes.SIGN_OUT);
      this.$store.dispatch(actionTypes.RESET);
      await this.$router.push("/sign-in");
      this.$router.go(0); // to reload routes history, so components will be rerendered
    }
  }
}
