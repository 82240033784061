import API from "@/http/http-client";
import ISavedReply from "@/interfaces/models/saved-reply.interface";

export const SavedRepliesService = {
  async fetchSavedReplies(): Promise<ISavedReply[]> {
    const {data} = await API.get('/dog/save-replies');
    return data;
  },

  async createSavedReply(reply: Partial<ISavedReply>): Promise<ISavedReply> {
    const {data} = await API.post('/dog/save-replies', reply);
    return data;
  },

  async updateSavedReply(reply: ISavedReply): Promise<ISavedReply> {
    const {data} = await API.patch(`/dog/save-replies/${reply.id}`, reply);
    return data;
  },

  async removeSavedReply(replyId: number): Promise<void> {
    await API.delete(`/dog/save-replies/${replyId}`);
  },
}
