import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#4D7CFE',
        secondary: '#98A9BC',
        accent: '#8c9eff',
        error: '#FE4D97',
        success: '#6DD230',
        warning: '#FFAB2B',
        info: '#2CE5F6'
      },
    },
  },
});

export default vuetify;
