import API from '@/http/http-client';
import { IAutomation } from '@/interfaces/models/IAutomation';
import { ICreateAutomation } from '@/interfaces/requests/ICreateAutomation';
import { IUpdateAutomation } from '@/interfaces/requests/IUpdateAutomation';

export const AutomationService = {
  async fetchAutomations(type: string | null): Promise<IAutomation[]> {
    const {data} = await API.get('/dog/automations', {
      params: {
        type: type
      }
    });
    return data;
  },

  async fetchAutomation(automationId: number): Promise<IAutomation> {
    const {data} = await API.get(`/dog/automations/${automationId}`);
    return data;
  },

  async createAutomation(payload: ICreateAutomation): Promise<IAutomation> {
    const {data} = await API.post('/dog/automations', payload);
    return data;
  },

  async updateAutomation(payload: IUpdateAutomation): Promise<IAutomation> {
    const url = `/dog/automations/${payload.id}`;
    delete payload.id;
    const {data} = await API.patch(url, payload);
    return data;
  },

  async removeAutomation(automationId: number): Promise<void> {
    await API.delete(`/dog/automations/${automationId}`);
  },

  async enableAutomation(automationId: number): Promise<IAutomation> {
    const {data} = await API.patch(`/dog/automations/${automationId}/enable`);
    return data;
  },

  async disableAutomation(automationId: number): Promise<IAutomation> {
    const {data} = await API.patch(`/dog/automations/${automationId}/disable`);
    return data;
  },
}
